<script setup>
	import { reactive } from 'vue';
	import { store } from '@/Store';
	import Faqs from './Faqs';

	const faqs = new Faqs();

	const tabs = reactive([]);
	const faqsList = reactive([]);

	function getFaqs(e) {
		faqsList.splice(0, faqsList.length, ...faqs.faqsToShow(e.target.id.split('-')[0]));
	}

	store.onInitialized(async () => {
		if (store.user.userId) {
			tabs.push({ id: 'obu', title: 'AstraZeneca Oncology Direct' });
			store.user.roles.forEach(role => {
				if (role.id.toLowerCase() === 'amgen') {
					tabs.push({ id: 'amgen', title: 'Amgen' });
				} else if (role.id.toLowerCase() === 'bbu') {
					tabs.push({ id: 'bbu', title: 'AstraZeneca BioPharma eStore' });
				}
			});
			faqsList.push(...faqs.faqsToShow(tabs[0].id));
		}
	});
</script>
<template>
	<div class="faqs-table table-responsive">
		<div v-if="tabs.length > 0">
			<TabBar :tabs="tabs" @click="getFaqs" />
			<div class="tab-content mt-4">
				<div class="tab-pane fade show active faqs-table table-responsive" role="tabpanel" aria-labelledby="info-tab" tabindex="0">
					<table class="table table-striped mw-100">
						<tbody>
							<tr v-for="faq in faqsList" :key="faq.id">
								<p><strong v-html="faq.ques"></strong></p>
								<td>
									<p v-html="faq.ans"></p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div v-else>
			<p>There are no FAQs available at this time. For assistance please fill out the form at <router-link to="contact?question=true">Contact Us</router-link>.</p>
		</div>
	</div>
</template>
<style lang="scss">
	.faqs-table .table-responsive {
		table {
			width: 100%;
			table-layout: fixed;

			tbody {
				display: flex;
				flex-direction: column;
				gap: 15px;

				strong {
					color: #1a1446;
				}

				p {
					margin-bottom: 0 !important;
				}

				tr {
					display: flex;
					flex-direction: column;

					td {
						white-space: normal !important;
						word-wrap: break-word;
						padding-top: 0 !important;

						ul {
							margin-top: 10px;
							padding-left: 25px !important;
						}

						p {
							font-size: 14px !important;
						}
					}
				}
			}
		}
	}
</style>
