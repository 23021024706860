

//Services for configuration for logged in User
export default class UserConfig {

    constructor(store) {
        this.store = store;

        this.isUomToBeBroken = false;
        this.defaultUnitOfMeasure = '';

        this.store.onSignedIn(async () => {
            await this.getUserConfig();
        });
    }

    /**
     * getting user configuration for the logged in user
     * related to whether he should see Uom in packs or eaches 
     * and other configurations can be added if required.
     */
    async getUserConfig() {
        var response = await this.store.api.get(`/api/site/user/current/config`);
        if (response) {
            this.isUomToBeBroken = response.isUomToBeBroken;
            this.defaultUnitOfMeasure = response.defaultUnitOfMeasure;
        }
    }
}