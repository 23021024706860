<script setup>

    // FavoritesPanel
    // Shows the favorites panel of the my account area.

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 12
    } 

    // Components ----
    import ProductGridTile from '@/site/components/ProductGridTile';
    import ProductListTile from '@/site/components/ProductListTile';
    import MockProductGridTile from '@/site/components/MockProductGridTile';
    import MockProductListTile from '@/site/components/MockProductListTile';

    // Imports ----
    import { store } from '@/Store';
    import { Search } from '@dd-nucleus/nucleus-vue'; 

    // State ----
    const search = new Search('products-for-user', 'tile', 'product-newest-to-oldest', constants.DEFAULT_PAGE_SIZE);

    const sortOptions = [
        { id: 'product-newest-to-oldest', text: 'Newest to Oldest' },
        { id: 'product-oldest-to-newest', text: 'Oldest to Newest' },
        { id: 'product-itemNumber', text: 'Item Number (A-Z)' }
    ];

    const pageSizeOptions = [12, 24, 36, 48, 60];

</script>

<template>
    <div class="account-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Favorites</span></h1>

        <SearchContainer :search="search" default-view="list" :deep-link="false" :filters="store.products.filters.favorite">

            <div class="row mb-3">
                <div class="col"><SortOptionsList :options="sortOptions" /></div>
                <div class="col"><PageSizeList :options="pageSizeOptions" /></div>
                <div class="col"><ViewModeSelector /></div>
                <div class="col-4 text-end"><PageSelector /></div>
            </div>

            <SearchGrid>
                <!-- Template for each item in a grid view -->
                <template v-slot:grid="product">

                    <ProductGridTile :product="product" />

                </template>

                <!-- Template for each item in a list view -->
                <template v-slot:list="product">

                    <ProductListTile :product="product" />

                </template>

                <!-- Template for no results -->
                <template #empty>
                    No products were found that match your criteria.
                </template>

                <!-- Template while results are initially loading -->
                <template #loading-grid>
                    <MockSearchGrid>
                        <MockProductGridTile />
                    </MockSearchGrid>
                </template>

                <template #loading-list>
                    <!-- TODO -->
                    <MockSearchGrid :mock-count="2">
                        <MockProductListTile />
                    </MockSearchGrid>
                </template>
            </SearchGrid>

            <div class="row">
                <div class="col text-end fit-content"><PageSelector /></div>
            </div>

        </SearchContainer>

    </div>
</template>

<style lang="scss">


</style>