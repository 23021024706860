<script setup>

    // SetPasswordForm
    // Provides link ID and new password to set a new user's password.

    // Components ----
    import { PasswordHints } from '@dd-nucleus/nucleus-vue';

    // Imports ----
    import { computed, reactive, ref, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import SetPasswordForm from '../forms/SetPasswordForm';
    import { store } from '@/Store';

    const router = useRouter();
    const route = useRoute();

    // State ----
    const form = reactive(new SetPasswordForm());
    const linkId = ref(route.params.id);
    const detail = reactive({ validateResetPasswordLink: {} });
    const isInvalidResetPasswordLink = ref(false);
    const isValidatingPassword = ref(false);

    // Handlers ----
    onMounted(async () => {
        detail.validateResetPasswordLink = Object.assign({}, await store.authenticator.validateResetPasswordLink(linkId.value));

        if (detail.validateResetPasswordLink.succeeded) {
            form.model.linkId = linkId.value;
            form.model.userName = detail.validateResetPasswordLink.userName;
            form.model.email = detail.validateResetPasswordLink.email;
        }
        else {
            isInvalidResetPasswordLink.value = true;
        }
    });

    function onCancel() {
        routeToSignIn();       
    }

    async function onSendEmail() {
        //Removed code after having discussion with Manoj as we dont need this here
        router.push({ path: '/sign-in/forgot-password' });
    }

    function onPasswordTextChange() {
        isValidatingPassword.value = true;
    }

    async function onSave() {
        await form.submit();

        isValidatingPassword.value = false;

        // TODO: catch and report if an error occurs in submit
        //if (false) {
        //    form.validator.addError('Your password reset request could not be completed.');
        //}
        //else {
        routeToSignIn();
        //    }
    }

    function routeToSignIn() {
        router.push({ path: '/' });
    }

    // Computed ----
    const showPasswordRequirements = computed(() => {
        return isValidatingPassword.value && form.model.password.length > 3;
    });

</script>

<template>
    <FormContainer :form="form">

        <FormMessages class="mb-2" />

        <div v-if="isInvalidResetPasswordLink">
            <div class="reset-password-header text-center mt-5 mb-3">
                <h1>Expired Set Password Link</h1>
            </div>
            <div class="alert alert-danger mb-3">
                <p>
                    The email link has already been used or has expired. You may request a new link by clicking the <strong>Request a New Link</strong> button below.
                </p>
            </div>
            <div class="row mt-4 mb-5">
                <div class="col col-6 d-grid">
                    <button class="btn btn-outline-color-2 me-2" @click="onCancel">Back to Sign In</button>
                </div>
                <div class="col col-6 d-grid">                   
                    <button class="btn btn-outline-color-2 me-2" @click="onSendEmail">Request New Link</button>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="reset-password-header text-center mt-5 mb-3">
                <h1>Set Password for {{ form.model.userName }}</h1>
            </div>
            <div class="text-center">
                <p>
                    Please enter a password and click <strong>Set Password</strong>.
                </p>
            </div>

            <div class="mt-3 mb-3">
                <FieldLabel field-name="password" label="New Password">
                    <TextBoxField v-model="form.model.password" placeholder="Password" :is-password="true" @keyup="onPasswordTextChange" show-pwd-btn-class="btn-color-2-xlt" />
                    <PasswordHints />
                </FieldLabel>

            </div>

            <div class="mb-3">
                <FieldLabel field-name="confirmPassword" label="Confirm Password">
                    <TextBoxField v-model="form.model.confirmPassword" placeholder="Confirm Password" :is-password="true" show-pwd-btn-class="btn-color-2-xlt" />
                    <div v-if="showPasswordRequirements" class="password-requirements-box">
                        <b>Password Requirements</b>
                        <FieldMessages field-name="password" :add-box="false" />
                    </div>
                </FieldLabel>
            </div>

            <div class="row mt-4 mb-5">
                <div class="col col-6 d-grid">
                    <button class="btn btn-outline-color-2 me-2" @click="onCancel">Cancel</button>
                </div>
                <div class="col col-6 d-grid">
                    <button class="btn btn-color-2" @click="onSave">Set Password</button>
                </div>
            </div>
        </div>

    </FormContainer>
</template>

<style lang="scss">
    .reset-password-header h1 {
        color: $black;
        font-weight: $normal;
    }

    .password-requirements-box {
        margin-top: 1rem;
    }
</style>
