<script setup>
// PageHeader
// Used as a header for all pages that use templates/PageTemplate.vue

// Components ----
import { PageHeaderContainer, OmniSearch } from '@dd-nucleus/nucleus-vue';

// Imports ----
import { computed, reactive, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { store } from '@/Store';

const router = useRouter();
const route = useRoute();

// State ----
const selection = reactive([]);
const showHamburgerMenu = ref(false);

// Computed ----
const cartTileClasses = computed(() => {
	return { 'cart-updating': store.cart.current.updating };
});

// Handlers ----
function onItemSelected(path) {
	// TODO: HACK: Only way to get search to refresh when on the search page
	if (route.name === 'ProductSearch') {
		window.location = '/products?f-product-category=' + path[0].id;
	} else {
		router.push('/products/?f-product-category=' + path[0].id);
		showHamburgerMenu.value = false;
	}
}


function toggleHamburgerMenu() {
	showHamburgerMenu.value = !showHamburgerMenu.value;
}

function onClickOutsideMenu() {
	showHamburgerMenu.value = false;
}

async function onSignOut() {
	await store.signOut(router);
}
</script>

<template>
	<PageHeaderContainer>
		<nav class="main-navbar navbar navbar-expand-md navbar-nucleus">
			<div class="container-fluid d-flex flex-fill">
				<div class="hamburger">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="nav-item dropdown">
							<a class="nav-link" href="#" @click="toggleHamburgerMenu" role="button">
								<i class="hamburger-icon bi bi-list"></i>
							</a>
						</li>
					</ul>
				</div>

				<router-link class="navbar-brand" to="/">
					<img src="/images/az-logo.png" alt="AstraZeneca" />
					<ShowOnlyForRoles :roles="['Amgen', 'Syneos']">
						<img src="/images/amgen-logo.png" alt="Amgen | AstraZeneca" />
					</ShowOnlyForRoles>
				</router-link>

				<div class="d-md-flex flex-md-fill">
					<div class="search-container flex-md-fill ps-md-3 pe-md-5 d-none d-md-block">
						<OmniSearch placeholder="search for products" />
					</div>
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="help-menu nav-item d-none d-lg-block" :class="{ authenticated: store.user.isAuthenticated }">
							<router-link class="nav-link active home-spacing" to="/">Home</router-link>
							<router-link class="nav-link active home-spacing" aria-current="page" to="/help/contact">Help <i class="bi-chevron-right"></i></router-link>
						</li>
						<li class="nav-item dropdown d-none d-md-block">
							<template v-if="store.user.isAuthenticated">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
									Hi {{ store.user.firstName }}<br />
									Your Account
									<i class="bi-chevron-right ms-md-1"></i>
								</a>
							</template>
							<template v-else>
								<a class="nav-link dropdown-toggle" href="#" role="button" aria-expanded="false"> Sign In </a>
							</template>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
								<li><router-link class="dropdown-item" to="/account/profile">My Profile</router-link></li>
								<li><router-link class="dropdown-item" to="/account/favorites">My Favorites</router-link></li>
								<li><router-link class="dropdown-item" to="/account/contacts">My Contacts</router-link></li>
								<li><router-link class="dropdown-item" to="/account/order-history">My Order History</router-link></li>
								<li><router-link class="dropdown-item" to="/account/password">My Password</router-link></li>

								<ShowOnlyForRoles :roles="[
										'Qualfon.CsdAdmin',
										'Qualfon.Support'
									]">
									<li><a class="dropdown-item" target="_blank" href="/pdf/AZ Conference Orders form_v1.5.pdf">Event Order Form</a></li>
								</ShowOnlyForRoles>

								<li><a class="dropdown-item" href="#" @click.cancel="onSignOut">Sign Out</a></li>
							</ul>
						</li>
					</ul>

					<div class="navbar-nav cart-container justify-content-end flex-row" :class="cartTileClasses">
						<router-link class="text-white" to="/cart">
							<i class="cart-icon bi-cart3"></i>
						</router-link>
						<div class="cart-text text-end ps-md-1">
							<router-link class="text-white no-underline" to="/cart">
								{{ store.cart.current.unitCount }}<br />
								Cart
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<Transition>
			<div v-if="showHamburgerMenu" class="hamburger-menu" aria-labelledby="hamburgerDropdown" v-click-outside="onClickOutsideMenu">
				<div class="header">Shop Products</div>
				<div class="menu-item"><router-link to="/products">All Products</router-link></div>
				<div class="categories-box">
					<TreeSelect class="filter-nav" :items="store.products.categories" :open-levels="1" :selection="selection" @selected="onItemSelected" />
				</div>
				<div class="header">Other Links</div>
				<div class="menu-item"><router-link to="/account/profile">My Profile</router-link></div>
				<div class="menu-item"><router-link to="/help/faqs">Frequently Asked Questions</router-link></div>
				<div class="menu-item"><router-link to="/help/contact">Contact Us</router-link></div>
				<div class="menu-item"><a href="#" @click.cancel="onSignOut">Sign Out</a></div>
			</div>
		</Transition>
	</PageHeaderContainer>
</template>

<style lang="scss">
	.home-spacing{
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

.hamburger {
	margin-right: 1rem;
	padding-top: 0.3rem;
}

    .hamburger-menu {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background-color: $white;
        z-index: 10000;
        position: absolute;
        left: 0px;
        width: 100%;

        .categories-box {
            max-height: 50vh;
            overflow-y: scroll;
        }

        .n-tree-select {
            .item {
            }

            .link,
            .link:visited {
                color: $color-2;
            }
        }

        .header {
            font-weight: $bold;
            margin-top: 0.5rem;
            color: $gray-50;
            border-bottom: 1px solid $gray-20;
        }

        .menu-item {
            padding: 0.5rem;
        }

        .menu-item:hover {
            background-color: $color-2-xlt;
        }

        .menu-item a,
        .menu-item a:visited {
            font-weight: $bold;
            color: $black;
            text-decoration: none;
        }

        .menu-item a:hover {
            text-decoration: none;
        }
    }

.hamburger-icon {
	font-size: 2rem;
}

.search-container {
	padding-top: 0.7rem;
}

.btn-search,
.btn-search:hover {
	border-top-right-radius: 0rem;
	border-bottom-right-radius: 0rem;
	border-top: 1px solid #999999 !important;
	border-right: 1px solid #999999 !important;
	border-bottom: 1px solid #999999 !important;
}

.btn-search:hover {
	background-color: $action-button-bg-color;
	color: $color-1;
}

.help-menu {
	padding-right: 3rem;
	white-space: nowrap;
}

.help-menu.authenticated {
	padding-top: 0.5rem;
}

.cart-container {
	width: 4rem;
}

.cart-updating {
	opacity: 0.2;
}

.cart-icon {
	font-size: 2rem;
}

.cart-text {
	padding-top: 0.8rem;
	color: $header-text-color;
	font-size: 0.8rem;
	line-height: 0.8rem;
}

.hamburger-menu {
	.n-tree-select {
		// Selected item
		.selected > .item {
			.link {
				color: $category-filter-selected-text-color;
				font-family: $category-filter-selected-font-name;
				font-weight: $category-filter-selected-font-weight;
				font-size: $category-filter-selected-font-size;
			}
		}
		// All levels
		.level .item {
			padding: $category-filter-item-padding;
			color: $category-filter-item-text-color;
			font-family: $category-filter-item-font-name;
			font-weight: $category-filter-item-font-weight;
			font-size: $category-filter-item-font-size;
		}

		.level .item > .link {
			color: $category-filter-item-text-color;
		}

		.level .item:hover {
			background-color: $category-filter-item-hover-bg-color;
		}
		// Level 1 items only
		.level-1 > .item {
			color: $category-filter-item-level-1-text-color;
			font-name: $category-filter-item-level-1-font-name;
			font-weight: $category-filter-item-level-1-font-weight;
			font-size: $category-filter-item-level-1-font-size;
		}

		.level-1 .item > .link {
			color: $category-filter-item-level-1-text-color;
		}
		// Level 2 items only
		.level-2 .item {
			color: $category-filter-item-level-2-text-color;
			font-name: $category-filter-item-level-2-font-name;
			font-weight: $category-filter-item-level-2-font-weight;
			font-size: $category-filter-item-level-2-font-size;
		}

		.level-2 > .item > .link {
			color: $category-filter-item-level-2-text-color;
		}
		// Level 3 items only
		.level-3 > .item {
			color: $category-filter-item-level-3-text-color;
			font-name: $category-filter-item-level-3-font-name;
			font-weight: $category-filter-item-level-3-font-weight;
			font-size: $category-filter-item-level-3-font-size;
		}

		.level-3 > .item > .link {
			color: $category-filter-item-level-3-text-color;
		}
		// Level 4 items only
		.level-4 > .item {
			color: $category-filter-item-level-4-text-color;
			font-name: $category-filter-item-level-4-font-name;
			font-weight: $category-filter-item-level-4-font-weight;
			font-size: $category-filter-item-level-4-font-size;
		}

		.level-4 > .item > .link {
			color: $category-filter-item-level-4-text-color;
		}
		// Level 5 items only
		.level-5 > .item {
			color: $category-filter-item-level-5-text-color;
			font-name: $category-filter-item-level-5-font-name;
			font-weight: $category-filter-item-level-5-font-weight;
			font-size: $category-filter-item-level-5-font-size;
		}

		.level-5 > .item > .link {
			color: $category-filter-item-level-5-text-color;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	.hamburger-menu {
		left: unset;
		width: 20rem;
		margin-top: -1.25rem;
	}

	.cart-container {
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.hamburger-menu {
		margin-top: -1rem;
	}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
</style>
