<script setup>

    // ShipToContact
    // Allows the user to select a single contact as the ship-to destination.

    // Components ----
    import MarketingContacts from '@/site/components/marketing/MarketingContacts';

    // Imports ----
    import { ref, onMounted, defineProps, defineEmits } from 'vue';
    import { store } from '@/Store';


    const props = defineProps({
        // If true, this will be styled for selecting multiple items.
        multiSelect: {
            type: Boolean,
            required: false,
            default: false
        },
        // Indicates whether the selection can be toggled on/off
        toggle: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // State ----
    const selection = ref([]);
    // Events ----
    const emit = defineEmits(['selected', 'deselected']);
    // Computed ----

    // Handlers ----
    onMounted(() => {
        update();
    });

    store.cart.onCartLoaded(() => {
        update();
    });

    store.cart.onCartUpdated(() => {
        update();
    });

    function update() {
        if (typeof store.cart.current.shipToList === 'undefined') return;
        if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToContact) {
            selection.value.push(store.cart.current.shipToList[0]?.marketingContactId);
        }
        else {
            selection.value = [];
            for (let contact of store.cart.current.shipToList) {
                if (contact.marketingContactId !== null && contact.marketingContactId !== undefined)
                    selection.value.push(contact.marketingContactId);
            }
        }
    }

    function onContactSelected(contact) {

        if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToContacts) {
            selection.value.push(contact.id);
            emit('selected', contact);
        }
        else {
            selection.value.push(contact.id);
            store.cart.setShipToMarketingContact(contact.id);
        }
    }

    function onContactDeselected(contact) {

        if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToContacts) {
            selection.value = selection.value.filter(x => x !== contact.id);
            emit('deselected', contact);
        }
    }

</script>

<template>

    <div class="ship-to-contact">
        <MarketingContacts @selected="onContactSelected"
                           @deselected="onContactDeselected"
                           :selection="selection"
                           :can-select="true"
                           :allow-create="props.multiSelect"
                           :multiSelect="props.multiSelect"
                           :toggle="props.toggle" />

    </div>

</template>

<style lang="scss">

    .ship-to-contact {
    }
</style>