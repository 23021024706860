<script setup>

    // ProfilePanel
    // Shows the profile panel of the my account area.

    // TODO: Add a (universally-located) scroll so that adding a new shipping address will bring the form into view.

    // Components ----
    import ShippingAddressTile from '@/site/components/ShippingAddressTile';
    import InfoIconPopover from '@/site/components/InfoIconPopover';

    // Imports ----
    import { ref, computed } from 'vue';
    import { store, DEFAULT_COUNTRY_CODE } from '@/Store';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import UpdateAccountInfoForm from '../forms/UpdateAccountInfoForm';
    import { ShippingAddressForm } from '@dd-nucleus/nucleus-vue';
    import { DEFAULT_ADDRESS_CHAR_LENGTH, INFOICON_MESSAGE_CONTACT_FIELD } from '@/constants';
    import { AddressConfig } from '@dd-nucleus/nucleus-vue';

    // State ----
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    const search = new Search('addresses-for-user', 'tile', 'address-sequence', 1000);
    search.setEmptyFilter('address-not-default');

    const editingAccount = ref(false);
    const addingShippingAddress = ref(false);
    const addressConfig = new AddressConfig();
    addressConfig.addressLine1.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.addressLine2.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.addressLine3.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.addressLine4.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.city.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;

    let accountInfoForm = new UpdateAccountInfoForm();
    let addShippingAddressForm = new ShippingAddressForm(null, null, addressConfig);

    const showAddressLines = computed(() => { 
        return addShippingAddressForm.model.addressLine2 ? 2 : 1;
    });

    // Handlers ----
    function onEditAccount() {
        accountInfoForm.init();
        editingAccount.value = true;
    }

    function onCancelEditAccount() {
        editingAccount.value = false;
    }

    async function onSaveAccount() {
        await accountInfoForm.submit();
        editingAccount.value = false;
    }

    function onAddShippingAddress() {
        addShippingAddressForm.model.countryCode = selectedCountry.value;
        addingShippingAddress.value = true;
    }

    function onCancelAddShippingAddress() {
        addingShippingAddress.value = false;
    }

    async function onSaveShippingAddress() {
        addingShippingAddress.value = false;
        await addShippingAddressForm.submit();

        await store.refreshSearches('addresses-for-user');
    }

    function onChanged(form) {
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
        }
    }


</script>

<template>
    <div class="account-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Profile</span></h1>

        <BodyPanel title="Account Information">
            <FormContainer :form="accountInfoForm">
                <div class="row g-2 mb-4">
                    <div class="col-2">Name:</div>
                    <div class="col-10"><b>{{store.user.firstName}} {{ store.user.lastName}}</b></div>

                    <div class="col-2 d-flex align-items-center">Username:</div>
                    <div v-if="editingAccount" class="col-10">
                        <TextBoxField v-model="accountInfoForm.model.userName" field-name="userName" />
                    </div>
                    <div v-else class="col-10">{{store.user.userName}}</div>

                    <div class="col-2 d-flex align-items-center">Email:</div>
                    <div v-if="editingAccount" class="col-10">
                        <TextBoxField v-model="accountInfoForm.model.email" field-name="email" />
                    </div>
                    <div v-else class="col-10">{{store.user.email}}</div>

                    <div v-if="editingAccount" class="col-12 pt-2">
                        <button class="btn btn-outline-color-2 me-2" @click="onCancelEditAccount">Cancel</button>
                        <button class="btn btn-color-2" @click="onSaveAccount">Save</button>
                    </div>
                    <div v-else class="col-12 pt-2"><ActionButton v-if="false" icon="bi bi-pencil-fill" label="Edit" @click="onEditAccount" /></div>
                </div>
            </FormContainer>
        </BodyPanel>

        <BodyPanel title="My Addresses">
            <div class="row g-2 mb-2">
                <div class="col-6 section-subtitle"><strong>Default Shipping Address</strong></div>
                <div class="col-6 section-subtitle"><strong>Account Address</strong></div>
            </div>
            <div class="row g-2">
                <div class="col-6"><ShippingAddressTile :address="store.shipping.addresses[0]" :border="false" /></div>
                <div class="col-6"><ShippingAddressTile :address="store.account.address" :border="false" /></div>
            </div>

            <div class="row mt-4">
                <div class="mb-2 section-subtitle"><strong>Other Shipping Addresses</strong></div>

                <div v-if="addingShippingAddress" class="subform mt-2" ref="shippingAddressForm">
                    <div class="col-12 col-md-8">
                        <div class="header">New Shipping Address</div>
                        <EditShippingAddress :form="addShippingAddressForm" :address-only="true" :selectedCountry="selectedCountry" @change="onChanged" :startAddressLines="showAddressLines" :maxAddressLines="2">
                            <template #country-content>
                                <InfoIconPopover :message="INFOICON_MESSAGE_CONTACT_FIELD" />
                            </template>
                            <template #state-content>
                                <InfoIconPopover :message="INFOICON_MESSAGE_CONTACT_FIELD" />
                            </template>
                        </EditShippingAddress>
                    </div>
                    <div class="col-12 pt-2">
                        <button class="btn btn-outline-color-2 me-2" @click="onCancelAddShippingAddress">Cancel</button>
                        <button class="btn btn-color-2" @click="onSaveShippingAddress" :disabled="addShippingAddressForm.status.isInvalid">Save</button>
                    </div>
                </div>

                <div v-else>
                    <SearchContainer :search="search" :deep-link="false">

                        <SearchGrid>
                            <!-- Template for each item in a grid view -->
                            <template v-slot:grid="address">

                                <ShippingAddressTile :address="address" :can-set-default="true">
                                </ShippingAddressTile>

                            </template>
                        </SearchGrid>

                    </SearchContainer>

                    <div class="mt-1">
                        <ActionButton icon="bi bi-plus-lg" label="Add Shipping Address" @click="onAddShippingAddress" />
                    </div>
                </div>
            </div>
        </BodyPanel>
    </div>
</template>

<style lang="scss">

    .section-subtitle {
        border-bottom: 1px solid $gray-10;
    }
</style>