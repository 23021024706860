export const Tags = {
    HCP: "Direct to HCP"
}

export const POBOXCODE = 'POBOX';
export const USCODE = 'US';
export const PEURTORICOCODE = 'PR';
export const AMA_URL = "https://az.app.box.com/s/dnswuqwmz225hf8w5lcp74vyfcyqo78c";
export const UserRole = {
    ConferenceOrderRole: "ConferenceOrderRole"
}

export const OrderType = {
    NOR: "NOR",
    LS: "LS"
}

export const DEFAULT_ADDRESS_CHAR_LENGTH = 30
export const INFOICON_MESSAGE_CONTACT_FIELD = "For Puerto Rico addresses select \"Puerto Rico\" from the Country dropdown.";
export const STOCK_EXCEED_MESSAGE = "Out of Stock";
