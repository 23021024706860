// Configuration values for Azure AD B2C

export const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_AD_CLIENT_ID,
        authority: process.env.VUE_APP_AD_AUTHORITY,                    
        redirectUri: process.env.VUE_APP_AD_REDIRECT_URI,
        postLogoutRedirectUri: process.env.VUE_APP_AD_POST_LOGOUT_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage",                              // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false                               // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    apiConfig: {
        scopes: [
            process.env.VUE_APP_AD_SCOPES
        ]
    },
    system: {}
};