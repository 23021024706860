<script setup>

    // ReviewPage
    // Allows the user to review their order before placing.

    // Components ----
    import CartBodyPanel from './components/CartBodyPanel';
    import ShipTo from './components/ShipTo';
    import CartItemList from './components/CartItemList';
    import { Tags } from '@/constants';

    // Imports ----
    import { ref, computed, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { store } from '@/Store';

    const router = useRouter();

    // State ----
    const isLoaded = ref(false);
    const placingOrder = ref(false);
    const isConferenceType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

    // Computed ----
    const canPlaceOrder = computed(() => {
        return (store.cart.current.canPlaceOrder === true && placingOrder.value === false);
    });

    // Handlers ----
    onMounted(() => {
        update();
    });

    store.cart.onCartLoaded(() => {
        isConferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
        update();
    });

    store.cart.onCartUpdated(() => {
        isConferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
        update();
    });

    // Called once we know we have a cart to work with
    function update() {
        isLoaded.value = true;
    }

    function onChangeShipTo() {
        router.push('/checkout');
    }

    function onChangeShipMethod() {
        router.push('/checkout');
    }

    function onChangeItems() {
        router.push('/cart');
    }

    async function onPlaceOrder() {
        placingOrder.value = true;

        const response = await store.cart.placeOrder('Cart');

        console.warn("response: ", response)

        //TODO: Commenting below line. need to understand the use of below line.
        //store.cart.ShipToMode.ShipToContacts

        if (response.succeeded)

            if (response.model.isMultiShip) {

                router.push('/order-confirmed/' + response.model.bulkOrderId);

            } else {

                router.push('/order-confirmed/' + response.model.id);

            }

        else {
            // TODO: How to get messages back?
            store.cart.current.canPlaceOrder = true;
            placingOrder.value = false;
        }
    }

    function hasTaggedItems(tagValue) {
        let result = false
        for (const item of store.cart.current.items) {
            const tags = item.product.tags;
            if (tags.map(tag => tag.name).includes(tagValue)) {
                result = true;
                break
            }
        }
        return result;
    }

    const hasHCPItems = computed(() => {
        return hasTaggedItems(Tags.HCP);
    })

</script>

<template>
    <PageTemplate>
        <div class="container root-page review-page">

            <div class="float-end">
                <router-link class="btn btn-outline-color-1 me-2" to="/checkout">BACK</router-link>
                <button class="btn btn-color-1" :disabled="!canPlaceOrder" @click="onPlaceOrder">PLACE ORDER</button>
            </div>

            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Review Order</span></h1>

            <div v-if="store.cart.current.requiresApproval" class="alert alert-warning alert-icon">
                <i class="bi bi-exclamation-triangle-fill"></i> &nbsp;When placed, this order will require approval before it can be fulfilled. See messages below.
            </div>

            <MessageList :messages="store.cart.current.messages" :add-box="false" class="alert alert-danger" />

            <div class="row">
                <div class="col">
                    <CartBodyPanel class="cart-body-panel">
                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangeShipTo" /></div>
                            <h2>Ship To</h2>
                        </template>

                        <ShipTo :editable="false" />

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel ship-method-panel">
                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangeShipMethod" /></div>
                            <h2>Ship Method</h2>
                        </template>

                        <div v-if="isLoaded">
                            <ShipMethod />
                        </div>

                    </CartBodyPanel>

                    <CartBodyPanel v-if="isConferenceType" class="cart-body-panel ship-method-panel">
                        <template #title>
                            <h2>Order Type</h2>
                        </template>

                        <div>
                            <p style="font-size: 16px">Conference Order</p>
                        </div>

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel order-items-panel">
                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangeItems" /></div>
                            <h2>Items</h2>
                        </template>

                        <CartItemList :editable="false" />

                    </CartBodyPanel>

                    <div class="hcp-box col-5" v-if="hasHCPItems">
                        <span>Reminder:</span>  Only items with a suffix of HCP or PAT (for consumer items) after the item number are considered critical items and can be shipped to HCPs. PIs will be automatically included for both HCP and PAT suffix items when required. Please ensure that you record IOV items in Veeva or OCE.
                    </div>

                    <p class="hcp-text"  v-if="hasHCPItems">This order will be shipped to an HCP.</p>

                    <div v-if="isLoaded" class="bottom-panel">
                        <div class="mb-2 text-sm">Order confirmation and shipping information will be sent to <strong>{{ store.cart.current.user.email }}</strong>.</div>
                        <button class="btn btn-color-1" :disabled="!canPlaceOrder" @click="onPlaceOrder">PLACE ORDER</button>
                    </div>


                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .root-page {
        min-height: 30rem;
    }

    .review-page {
        .ship-method .title {
            font-weight: bold;
        }

        .panel-title-action {
            margin-top: -0.75rem;
        }

        .bottom-panel {
            margin-top: 8px;
        }

        .hcp-box {
            background-color: #e9eef8;
            padding: 8px 6px;
            border: solid 1px lightgray;

            span {
                font-weight: bold;
            }
        }

        .hcp-text {
            margin: 0px;
            margin-top: 18px;
            font-weight: bold;
            font-size: 0.9rem !important;
        }
    }

</style>