
// SignInForm
// Implements the form that signs a user in.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';

export default class SignInForm extends Form {

    // Constructor
    constructor(router) {
        // Pass our model to the base class
        super(
          {
            username: '',
            password: '',
            keepSignedIn: false
          });

        this.router = router;

        this.validator.fields.username.isRequired = true;
        this.validator.fields.password.isRequired = true;

        // If we want the form to validate immediately based on automatic validations (e.g. to disable a button), do so here
        this.validate();
    }

    onValidate() {
        super.onValidate();
    }

    async onValidateAsync() {
        super.onValidateAsync();

        if (this.model.password !== '') {
            // TODO: Validate password via call to API
            // TODO: Add any messages to the....the what?
        }
    }

    async onSubmit() {
        const response = await store.signIn(this.model.username, this.model.password, this.router);
        if (!response.success) {
            // Show messages
            this.validator.addError(response.errorMessage);
        }
    }

    async onADSignIn() {
        this.router.push({ name: 'ADSignIn' });
    }
}