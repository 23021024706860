<script>
    // CartItemTile
    // A tile for an item in the cart.

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        // tileHeight: '8rem', // Height of the full tab, fixed for alignment purposes.
        // Removing fixed tile height because of companion items
        productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;
</script>

<script setup>
    // Imports ----
    import { reactive, defineProps, computed, ref, defineEmits, watch } from 'vue';
    import { store } from '@/Store';
    import LimitMessages from '@/site/components/LimitMessages';
    import { UpdateCartItemQtyForm, CompanionItemTile } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        // The cart item
        item: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        removable: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----
    const form = reactive(new UpdateCartItemQtyForm(props.item));

    const emits = defineEmits(['quantityChanged'])

    const customUnit = computed(() => {
        if (!store?.userconfig?.isUomToBeBroken) return ""

        return store?.userconfig?.defaultUnitOfMeasure
    })

    // Handlers ----
    async function onRemoveFromCart() {

        await store.cart.removeItem(props.item.id);
        emits('quantityChanged', { productId: props.item.product.id, hasLimitExceeded: null, hasStockExceeded: null })
    }

    const conferenceType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

    store.cart.onCartLoaded(() => {
        conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

    store.cart.onCartUpdated(() => {
        conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";

        const hasLimitExceeded = form.isLimitExceeded ?? false;
        const hasStockExceeded = form.isStockExceeded ?? false;

        emits('quantityChanged', { productId: props.item.product.id, hasLimitExceeded, hasStockExceeded })
    });

    const companionItems = computed(() => {
        let companionProducts = props.item.product.companionProducts;
        return companionProducts.map(({ companionProductNumber, companionProductThumbnailImageUrl, productId, companionProductId, companionProductName, quantity, isCalculated }) => ({
            itemNumber: companionProductNumber,
            thumbnailImageUrl: companionProductThumbnailImageUrl,
            parentProductId: productId,
            id: companionProductId,
            name: companionProductName,
            quantity: isCalculated ? props.item.quantity * quantity : quantity,
            isCalculated
        }));
    });

    const stockExceededMessage = ref('')

    watch(() => form, () => {
        const hasLimitExceeded = form.isLimitExceeded ?? false;
        const hasStockExceeded = form.isStockExceeded ?? false;
        stockExceededMessage.value = form.stockExceededMessage;
        emits('quantityChanged', { productId: props.item.product.id, hasLimitExceeded, hasStockExceeded })
    }, { deep: true, immediate: true });



</script>

<template>
    <div class="cart-item-tile" :style="{ height: setup.tileHeight }">
        <div v-if="removable" class="float-end remove"><ActionButton icon="bi bi-x-lg" label="Remove" @click="onRemoveFromCart" /></div>
        <div class="inner-row d-flex flex-row">
            <div class="left">
                <ProductThumbnail :product="props.item.product" :height="setup.tileHeight" no-image-available-url="/images/no-image.png" />
            </div>
            <div class="middle flex-fill">
                <!-- Stretchy middle part-->
                <div>
                    <div class="tags mb-2">
                        <ProductTags :product="props.item.product" />
                    </div>

                    <!-- Product name and item number -->
                    <router-link class="title-link" :to="`/product/${props.item.product.id}`">
                        <div class="name" v-snip="{ lines: setup.productNameLines }" :title="item.product.name">
                            {{ props.item.product.name }}
                        </div>
                    </router-link>
                    <div class="item-number">
                        <router-link class="title-link" :to="`/product/${props.item.product.id}`">{{ props.item.product.itemNumber }}</router-link>
                    </div>
                </div>
                <div class="companion-list" v-for="companion in companionItems" :key="companion.productId">
                    <CompanionItemTile class="companion" :companion="companion" :errorMessages="props.item.messages" />
                </div>
            </div>
            <!-- Fixed right panel -->
            <div class="right d-flex flex-column" :style="{ height: setup.tileHeight }">
                <FormContainer :form="form">
                    <div class="flex-fill">
                        <CartItemQty v-if="!store.cart.isMultiShip()" :item="props.item" :editable="props.editable" :unit="customUnit" :validate="!conferenceType" />
                        <div v-if="!conferenceType && props.item.messages.length === 0">
                            <FormMessages field-name="quantity" :add-box="false" class="mt-2" />
                        </div>
                        <FieldMessages field-name="quantity" class="mt-2" />
                        <LimitMessages v-if="!store.cart.isMultiShip()" :product="item.product" class="mt-2" />
                        <p class="stockExceeded" v-if="stockExceededMessage && conferenceType"> <i class="bi bi-exclamation-circle-fill" /> {{ stockExceededMessage }}</p>
                    </div>
                </FormContainer>
                <div class="messages">
                    <MessageList :messages="props.item.messages" :add-box="false" />
                </div>
                <div class="favorite">
                    <ProductFavoriteIcon :product="props.item.product" label="Add as Favorite" selected-label="Favorite" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .cart-item-tile {
        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .left {
            width: 8rem;
        }

        .name {
            font-size: 1rem;
            line-height: 1.1rem;
        }

        a.title-link,
        a.title-link:visited,
        a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .companion-list {
            display: flex;
            flex-direction: column;
        }

        .companion {
            display: flex;
            align-items: center;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }

        .middle {
            padding-left: 1rem;
            width: 80%;
        }

        .favorite {
            margin-top: 1rem;
        }

        .right {
            width: 14rem;
        }

        .remove {
            margin-top: 0.5rem;
        }
    }
</style>
