<script setup>
    import { store } from '@/Store';
    import { UserRole } from '@/constants';
    import { ref, defineProps, computed } from 'vue';
    import Popper from 'vue3-popper';

    //import { Util } from '@dd-nucleus/nucleus-vue'

    // Props ----
    const props = defineProps({
        // The cart item
        title: {
            type: String,
            required: true
        }
    });

    const isConferenceOrderType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

    async function updateConferenceType() {
        await store.cart.overrideProductLimits(!isConferenceOrderType.value)
    }

    store.cart.onCartLoaded(() => {
        isConferenceOrderType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

    store.cart.onCartUpdated(() => {
        isConferenceOrderType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

    const hasConferenceRole = computed(() => {
        return store.user.roles?.filter(role => role.id == UserRole.ConferenceOrderRole)?.length > 0;
    });
    

</script>

<template>
    <div class="form-check mt-2" v-if="hasConferenceRole">
        <input class="form-check-input" type="checkbox" :checked="isConferenceOrderType" @change="updateConferenceType">
        <label class="form-check-label" for="flexCheckDefault">
            {{ props.title }}
            <Popper arrow>
                <i class="info-icon bi bi-info"></i>
                <template #content>
                    <div class="popper-content">
                        By checking this box, limits applied on the product will be bypassed. <br /><br /><span style="font-weight: bold;">Note:</span> Quantity added to the cart will be dependant on the quantity available in the stock.
                    </div>
                </template>
            </Popper>
        </label>
        

    </div>
</template>

<style lang="scss">
    .info-icon {
        font-size: 12px;
        margin-left: 4px;
        cursor: pointer;
        background: lightgray;
        border-radius: 16px;
    }
</style>