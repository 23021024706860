<script setup>
    import { onMounted, ref } from 'vue';
    import { store } from '@/Store';
    import { JwtAuthenticator } from '@dd-nucleus/nucleus-vue';
    import { useRoute, useRouter } from 'vue-router'
    import { PageHeaderContainer } from '@dd-nucleus/nucleus-vue';

    const loading = ref(true)
    const errorMessage = ref("");

    onMounted(async () => {
        loading.value = true

        var route = useRoute();
        var router = useRouter();

        const auth = new JwtAuthenticator(store, 'api/httppost', router);
        const data = {
            postData: encodeURIComponent(route.query.inputdata)
        }
        const httpOptions = {
            headers: { 'content-type': 'application/json' }
        };

        const response = await auth.signInWithHttpPost(data, httpOptions);
        if (response && !response.succeeded) {
            if (response?.data?.messages?.length > 0 && response?.data?.messages[0]?.debugMessage) {
                errorMessage.value = response?.data?.messages[0]?.debugMessage
            } else {
                errorMessage.value = "Unable to process your request. Please contact support.";
            }
        }
        else {
            await store.userconfig.getUserConfig();
        }
        loading.value = false;
    });
</script>

<template>
    <PageHeaderContainer>
        <div class="sign-in-header-bar">
            <div>
                <img src="/images/order-pointe-logo.png" alt="AstraZeneca">
            </div>
        </div>
    </PageHeaderContainer>

    <div class="httppostStyle">
        <p v-if="loading">Processing you request ...</p>
        <p v-if="errorMessage">{{ errorMessage }}</p>
    </div>
</template>

<style>
    .httppostStyle {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20%;
    }

    .sign-in-header-bar {
        padding: 2rem;
        text-align: center;
    }
</style>