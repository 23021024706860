<script>
// ProductDetail
// Full detail for a product

// Setup ---
// Use the values below to modify how the tile appears
const setup = {
    productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
};

export const Setup = setup;

// Components ----
import LimitMessages from './LimitMessages';
import ProductPdfControls from './ProductPdfControls.vue';
</script>

<script setup>
// Imports ----
import { reactive, ref, defineProps, computed, watch, onMounted } from 'vue';
import { AddToCartForm, CompanionItemTile, Carousel, GeneralModal } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';
import ConferenceOrderTile from '@/site/components/ConferenceOrderTile'
import { STOCK_EXCEED_MESSAGE } from '@/constants';

// Props ----
const props = defineProps({
    // The product whose information to show.
    // API model: DD.Nucleus.Storefront.Api.ProductDetailModel
    productId: {
        type: String,
        required: true
    }
});

// State ----
const productId = ref(props.productId);
const form = new AddToCartForm({ id: props.productId }, 1, STOCK_EXCEED_MESSAGE);
const detail = reactive({ product: {} });

// Computed ----
const product = computed(() => {
    return detail.product;
});

const quantityLabel = ref('');
const modalImage = ref(null);
const imageSelectedUrl = ref(null);

const hasLimitExceeded = ref(false)

const hasStockExceeded = ref(false)

watch(() => form.model, () => {

    hasLimitExceeded.value = form.limits?.length > 0 ?? false;
    hasStockExceeded.value = form.product.quantityAvailable < form.model.quantity ? true : false;
}, { deep: true, immediate: true });

const showInStockQuantity = computed(() => {
    if (product.value) {
        return product.value.limits == null || product.value.limits.length == 0;
    }
    return false;
});

const productMedia = computed(() => {
	let productSlides = [];
	if (product.value?.media?.length > 0) {
		product.value?.media?.forEach(t => {
			if (!t.url.includes('pdf')) productSlides.push(t.url);
		});
	}
	return productSlides;
});

const customUnit = computed(() => {
    if (!store?.userconfig?.isUomToBeBroken) return '';

    return store?.userconfig?.defaultUnitOfMeasure;
});

const companionItems = computed(() => {
    return product.value?.companionProducts?.map(({ companionProductNumber, companionProductThumbnailImageUrl, productId, companionProductId, companionProductName, quantity }) => ({
        itemNumber: companionProductNumber,
        thumbnailImageUrl: companionProductThumbnailImageUrl,
        parentProductId: productId,
        id: companionProductId,
        name: companionProductName,
        quantity
    }));
});

// Handlers ----
onMounted(async () => {
    detail.product = Object.assign(detail.product, await store.products.getProductDetail(productId.value));
    form.product = detail.product;
    form.validate();
    quantityLabel.value = store.config.store.unitOfMeasures.find(unit => unit.id?.toLowerCase() === product.value.unitOfMeasure?.toLowerCase())?.text ?? null;
    console.log('Product is ', form.product);
    if (!form.product.quantityAvailable) {
        hasStockExceeded.value = true;
    }
});

const conferenceType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

store.cart.onCartLoaded(() => {
    conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
});

store.cart.onCartUpdated(() => {
    conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
});

function onImageSelected(url) {
	imageSelectedUrl.value = url;
	modalImage.value.open();
}
function disableLightbox() {
	modalImage.value.close();
}
</script>

<template>
    <div class="product-detail row">
        <div class="col-12 col-sm-7">
			<ProductImageGallery :product="product" v-if="productMedia.length <= 1" />
			<Carousel v-else :slides="productMedia" @imageSelected="onImageSelected" />
        </div>

        <div class="col-12 mt-2 col-sm-5 mt-sm-0">
            <div class="d-flex flex-column">
                <div class="middle flex-fill">
                    <!-- Stretchy middle part-->

                    <div class="tags">
                        <ProductTags :product="product" />
                    </div>

                    <!-- Product name and item number -->
                    <div class="name" :title="product.name">
                        {{ product.name }}
                    </div>
                    <div class="item-number">{{ product.itemNumber }}</div>
                    <LimitMessages :product="product" class="mt-2" />
                    <span v-if="showInStockQuantity" class="filter-messages">Qty in stock: {{ product.quantityAvailable
                        }} </span>
                    <div class="description mt-2" v-html="product.description"></div>

                    <div class="mt-3 mb-3">
                        <ProductFavoriteIcon :product="product" label="Add as Favorite" selected-label="Favorite" />
                    </div>
                </div>
                <div class="bottom">
                    <div class="row">
                        <div class="col-12 col-10 col-lg-8 coming-soon" v-if="product.isAvailableSoon">
                            <i class="bi bi-hourglass-split"></i>
                            <i>
                                <p>Coming soon...</p>
                            </i>
                        </div>
                        <div class="col-12 col-10 col-lg-8" v-else>
                            <MessageList :messages="product.messages" :add-box="false" />
                            <p v-if="product.quantityPerUnit > 0 && !store?.userconfig?.isUomToBeBroken">
                                Quantity: {{ product.quantityPerUnit }} {{ product.quantityPerUnit > 1 ? 'units' :
                                'unit' }} per {{ quantityLabel }}
                            </p>
                            <p v-if="store?.userconfig?.isUomToBeBroken">Quantity: 1 unit / {{
                                store?.userconfig?.defaultUnitOfMeasure }}</p>
                            <FormContainer :form="form" v-if="product.canAddToCart">
                                <ProductAddToCartQty :product="product" :unit="customUnit" />
                                <div>
                                    <FormMessages :showOnlySuccessMessagesInStock="conferenceType ? true : false"
                                        :add-box="false" class="mt-2" />
                                </div>
                                <FieldMessages field-name="quantity" class="mt-2" />
                                <ConferenceOrderTile
                                    v-if="form.validator.isInvalid && !conferenceType && hasLimitExceeded && !hasStockExceeded"
                                    title="Conference Order" />
                                <div class="d-grid mt-3">
                                    <button class="btn btn-outline-color-1"
                                        @click="form.submit(validate = !conferenceType)"
                                        :disabled="conferenceType ? hasStockExceeded : (form.status.isInvalid || hasStockExceeded)">ADD
                                        TO CART</button>
                                </div>

                                <ProductPdfControls :media="product.media" />
                            </FormContainer>
                        </div>
                    </div>
                </div>
                <div class="companion-list">
                    <CompanionItemTile class="companion" :companion="companion" v-for="companion in companionItems"
                        :key="companion.productId" />
                </div>
            </div>
        </div>
    </div>
    <div class="product-detail-modal">
		<general-modal ref="modalImage">
			<span class="close-btn" @click="disableLightbox">&times;</span>
			<img @click.stop :src="imageSelectedUrl" :alt="imageSelectedUrl" />
		</general-modal>
	</div>
</template>

<style lang="scss">
.product-detail {
    min-height: 30rem;

    img {
		object-fit: contain !important	;
	}
	.general-dialog {
		img {
			min-width: 500px !important;
			object-position: center !important;
			object-fit: contain !important;
			max-height: 75vh !important
		}
	}
	.lightbox {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-modal {
		position: relative;
		img {
			min-width: 500px !important;
			object-position: center !important;
			object-fit: contain !important;
			max-height: 75vh !important
		}
		.close-btn {
			position: absolute;
			top: 0;
			right: 10px;
			font-size: 28px;
			font-weight: bolder;
			cursor: pointer;
		}
	}

    .coming-soon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3rem auto 0 auto;

        .bi-hourglass-split {
            margin-right: 0.75rem;
            font-size: 1.5rem;
        }

        p {
            margin: 0;
            font-size: 1.25rem;
        }
    }

    .companion-list {
        margin-top: 20px;

        .companion-items-con {
            width: 100%;
        }
    }

    p {
        font-size: 12px;
    }

    .name {
        font-size: 1.5rem;
        line-height: 1.6rem;
    }

    .title-link {
        text-decoration: none;
    }

    .item-number {
        font-size: 0.7rem;
        color: $gray-50;
    }

    .n-product-add-to-cart-qty .text-box {
        border-color: #dddddd;
    }

    .btn[disabled] {
        opacity: 0.2;
    }
}
</style>
