// SignInForm
// Implements the form that signs a user in.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';

export default class ContactUsForm extends Form {
	// Constructor
	constructor() {
		// Pass our model to the base class
		super({
			topic: '',
			name: '',
			email: '',
			division: '',
			phone: '',
			orderNumber: '',
			message: ''
		});

		// Set required fields
		this.validator.fields.topic.isRequired = true;
		this.validator.fields.name.isRequired = true;
		this.validator.fields.name.disabled = true;
		this.validator.fields.email.isRequired = true;
		this.validator.fields.email.isEmail = true;
		this.validator.fields.message.isRequired = true;
		this.validator.fields.email.maxLength = 50;
		this.validator.fields.phone.minLength = 14;
		this.validator.fields.phone.maxLength = 14;
		this.validator.fields.orderNumber.maxLength = 50;
		this.validator.fields.message.maxLength = 500;
		this.validator.fields.orderNumber.isRequired = false;

		this.validate();

		this.topics = [
			{ id: 'Account Issue', text: 'Account Issue' },
			{ id: 'Feedback', text: 'Feedback' },
			{ id: 'Order Status/Tracking', text: 'Order Status/Tracking' },
			{ id: 'Placing an Order', text: 'Placing an Order' },
			{ id: 'Other', text: 'Other' },
			{ id: 'I have a question', text: 'I have a question'}
		];
	}

	onValidate() {
		super.onValidate();
	}

	async onValidateAsync() {
		super.onValidateAsync();
	}

	async onSubmit() {
		// Call endpoint to send the request
		var response = await store.api.post('api/site/contact-us', this.model);
		if (response.succeeded) {
			return true;
		} else {
			this.store.toast.error(`Something went wrong. Please contact support team!`);
			this.validator.addMessages(response.messages);
			this.store.handleApiError(response);
		}
	}
}
