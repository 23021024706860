<script setup>

// OrderHistoryTile
// A tile used for each order in order history.

// Components ----

// Imports ----
import { defineProps, computed } from 'vue';

// Props ----
const props = defineProps({
    // The order whose information to show.
    // API model: DD.Nucleus.Storefront.Api.OrderTileModel
    order: {
        type: Object,
        required: true
    }
});

// Computed ----
const icon = computed(() => {
    // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
    switch (props.order.orderStatusCode) {
        default: // None
            return '';
        case 1: // Pending
            return 'bi bi-check-circle-fill';
        case 2: // PendingApproval
            return 'bi bi-pause-circle-fill';
        case 99: // Canceled
            return 'bi bi-x-circle-fill';
        case 100: // Completed
            return 'bi bi-check-circle-fill';
    }
});

const cssClass = computed(() => {
    // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
    switch (props.order.orderStatusCode) {
        default: // None
            return '';
        case 1: // Pending
            return 'pending';
        case 2: // PendingApproval
            return 'pending-approval';
        case 99: // Canceled
            return 'canceled';
        case 100: // Completed
            return 'completed';
    }
});

// Methods ----

</script>

<template>
    <span class="order-history-status" :class="cssClass"><i :class="icon"></i> {{ order.orderStatus }}</span>
</template>

<style lang="scss">
.order-history-status {
    margin-top: 0.5rem;
    font-weight: $bold;
    font-size: 0.7rem;
}

.order-history-status.pending {
    .bi {
        opacity: 0.5;
        color: $ok;
    }
}

.order-history-status.pending-approval {
    .bi {
        color: $warning;
    }
}

.order-history-status.canceled {
    .bi {
        color: $error;
    }
}

.order-history-status.completed {
    .bi {
        color: $ok;
    }
}
</style>