import { Util } from '@dd-nucleus/nucleus-vue';
import { POBOXCODE, USCODE, PEURTORICOCODE } from '@/constants';
export default class ShippingMethodHelper {

    // Constructor
    constructor(store) {
        this.store = store;
    }

    async updatesShippingMethods(addressLine, countryCode) {
        let filters = {};
        if (Util.isNotNull(addressLine)) {
            addressLine = addressLine.toUpperCase();
        }
        if (Util.containsPoBox(addressLine) && countryCode === USCODE) {
            filters.POBOX = POBOXCODE;
        }
        else if (countryCode === PEURTORICOCODE) {
            filters.Country = PEURTORICOCODE;
        }
        else {
            filters.Country = USCODE;
        }
        await this.store.shipping.loadShipMethodsByFilters(filters);

        if (!this.store.cart.current.shipMethodCode || (this.store.cart.current.shipMethodCode && !this.isSelectedShippmentAvailable())) {
            this.store.cart.current.shipMethodCode = this.store.shipping.methods[0].code;
            await this.store.cart.setShipMethod(this.store.shipping.methods[0].code);
        }
    }

    isSelectedShippmentAvailable() {
        const selectedShippment = this.store.cart.current.shipMethodCode;
        return this.store.shipping.methods.filter((method) => method.code === selectedShippment).length > 0
    }

}
