<script setup>
    // ShipToUser
    // Allows the user to ship to a new address, pick from an existing address, or edit existing addresses, if store options for doing so are enabled.

    // Constants ----
    const View = {
        EDITABLE: 'EDITABLE',
        EDITING: 'EDITING',
        SUMMARY: 'SUMMARY'
    };

    const AddressOption = {
        SAVED_ADDRESS: 'SAVED',
        OTHER: 'OTHER'
    };

    const defaultAddressValue = 'Not Set';

    // Components ----
    import ShippingAddressTile from '@/site/components/ShippingAddressTile';
    import InfoIconPopover from '@/site/components/InfoIconPopover';
    import CartBodyPanel from './CartBodyPanel';

    // Imports ----
    import { reactive, ref, defineProps, computed, onMounted, watch, defineEmits } from 'vue';
    import { store, DEFAULT_COUNTRY_CODE } from '@/Store';
    import ShipToUserForm from '../forms/ShipToUserForm';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { AddressConfig } from '@dd-nucleus/nucleus-vue';
    import { DEFAULT_ADDRESS_CHAR_LENGTH, INFOICON_MESSAGE_CONTACT_FIELD } from '@/constants';

    // Props ----
    const props = defineProps({
        // The label to show with the icon on the button.
        canEdit: {
            type: Boolean,
            required: true
        }
    });

    // State ----
    const addressConfig = new AddressConfig();
    addressConfig.addressLine1.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.addressLine2.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.addressLine3.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.addressLine4.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;
    addressConfig.city.maxLength = DEFAULT_ADDRESS_CHAR_LENGTH;

    const form = reactive(new ShipToUserForm(addressConfig));
    const cart = reactive(store.cart.current);
    const addressOption = ref('');
    const hasWatcherTriggered = ref(false);
    const selectedAddressId = ref('');
    const isLoaded = ref(false);
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    const keyIndex = ref(0);
    const emit = defineEmits(['addressType', 'invalidAddress']);

    const search = new Search('addresses-for-user', 'tile', 'address-sequence', 1000);
    search.setEmptyFilter('address-shipping');

    const options = [
        { id: AddressOption.SAVED_ADDRESS, text: 'Saved Address' },
        { id: AddressOption.OTHER, text: 'Other Address' }
    ];

    // Computed ----
    const pageView = computed(() => {
        if (props.canEdit) {
            return View.EDITING;
        }
        // Summary view - display only
        else return View.SUMMARY;
    });

    const cartAddressOption = computed(() => {
        return addressOption.value;
    });

    const showAddressLines = computed(() => {
        return form.model.addressLine2 ? 2 : 1
    });

    // Methods ----

    // Handlers ----
    onMounted(() => {
        update();
        emit('addressType', { addValue: addressOption.value, addressId: selectedAddressId.value });
    });

    store.cart.onCartLoaded(() => {
        update();
    });

    store.cart.onCartUpdated(() => {
        update();
    });

    // Called once we know we have a cart to work with
    async function update() {
        if (typeof store.cart.current.shipToList  === 'undefined' || store.cart.current.shipToList.length === 0) return;
        
        if (store.cart.current.shipToMode === store.cart.ShipToMode.ShipToUser) selectedAddressId.value = store.cart.current.shipToList[0].contactAddressId;
        else selectedAddressId.value = '';

        if (cart.shipToMode === store.cart.ShipToMode.ShipToUser && cart.shipToList[0]?.contactAddressId === null) {
            addressOption.value = AddressOption.OTHER;

            const shipTo = store.cart.current.shipToList[0];
            form.model.companyName = shipTo.companyName;
            form.model.addressLine1 = shipTo.addressLine1;
            form.model.addressLine2 = shipTo.addressLine2;
            form.model.addressLine3 = shipTo.addressLine3;
            form.model.addressLine4 = shipTo.addressLine4;
            form.model.city = shipTo.city;
            form.model.state = shipTo.state;
            form.model.postalCode = shipTo.postalCode;
            form.model.countryCode = shipTo.countryCode;
        } else addressOption.value = AddressOption.SAVED_ADDRESS;

        if (store.cart.current?.shipToList?.length) {
            await validateAddress(store.cart.current.shipToList[0]);
        }
        isLoaded.value = true;
    }

    async function validateAddress(address) {
        if (address.addressLine1?.trim().toLowerCase() == defaultAddressValue.toLowerCase() || address.city.trim().toLowerCase() == defaultAddressValue.toLowerCase()) {
            emit('invalidAddress', true);
        } else {
            emit('invalidAddress', false);
        }
    }
    
    async function onAddressSelected(address) {
        await store.cart.setShipToUserSavedAddress(address.id);
        store.refreshSearches('addresses-for-user');
        await store.shippingMethodHelper.updatesShippingMethods(address.addressLine1, address.countryCode);
        await validateAddress(address);
    }

    async function onOtherAddressChanged() {
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
        }
        // Call the form to update the address, but we don't want validation to prevent submission.
        await form.submit(false);
        await store.shippingMethodHelper.updatesShippingMethods(form.model.addressLine1, form.model.countryCode);
        await validateAddress(form.model);
    }

    // Trigger on page load only once to load shipping methods
    watch(
        () => [addressOption.value, selectedAddressId.value],
        async () => {
            
            emit('addressType', { addValue: addressOption.value, addressId: selectedAddressId.value });

            ///Needs to be triggered as an when changed the address type radio button to emit whether address is valid or not.
            if (addressOption.value === AddressOption.OTHER) {
                await validateAddress(form.model);
            } else if (addressOption.value === AddressOption.SAVED_ADDRESS && cart.shipToList.length > 0) {
                await validateAddress(cart.shipToList[0]);
            }

            if (hasWatcherTriggered.value) return;
            if (!addressOption.value) return;
            hasWatcherTriggered.value = true;

            if (addressOption.value === AddressOption.OTHER) {
                await store.shippingMethodHelper.updatesShippingMethods(form.model.addressLine1, form.model.countryCode);
            }

            if (addressOption.value === AddressOption.SAVED_ADDRESS && cart.shipToList.length > 0) {
                await store.shippingMethodHelper.updatesShippingMethods(cart.shipToList[0].addressLine1, cart.shipToList[0].countryCode);
            }
        },
        { immediate: true }
    );

    watch(
        () => form,
        () => {
            if (addressOption.value === AddressOption.OTHER) {
                emit('addressType', { addValue: addressOption.value, addressId: null, formIsValid: !form.validator.isInvalid });
            }
        },
        {
            deep: true
        }
    );
</script>

<template>
    <div class="row">
        <div class="ship-to-user col-md-9">
            <div v-if="pageView === View.EDITABLE" class="p-3">
                <ShippingAddressTile v-if="isLoaded" :address="store.cart.current.shipToList[0]" :border="false"> </ShippingAddressTile>
            </div>

            <template v-if="pageView === View.EDITING">
                <div class="mb-3">
                    <RadioButton v-for="option in options"
                                 :key="option.id"
                                 v-model="addressOption"
                                 :label="option.text"
                                 list-id="address-option"
                                 :value="option.id"
                                 class="d-inline-block me-3" />
                </div>

                <div v-if="cartAddressOption === AddressOption.SAVED_ADDRESS">
                    <SearchContainer :search="search" :deep-link="false">
                        <SearchGrid :columns-xs="1" :columns-sm="2" :columns-md="2" :columns-lg="3" :columns-xl="3" :columns-xxl="3">
                            <!-- Template for each item in a grid view -->
                            <template v-slot:grid="address">
                                <TileSelectButton :item="address" :multi-select="false" :is-selected="address.id === selectedAddressId" @selected="onAddressSelected(address)" />
                                <ShippingAddressTile :class="{ selected: address.id === selectedAddressId }" :address="address" :can-set-default="true" height="9rem">
                                </ShippingAddressTile>
                            </template>
                        </SearchGrid>
                    </SearchContainer>
                </div>

                <div v-if="cartAddressOption === AddressOption.OTHER">
                    <div class="col-12 col-md-9 col-lg-6">
                        <EditShippingAddress :form="form" @change="onOtherAddressChanged" :selectedCountry="selectedCountry" :startAddressLines="showAddressLines" :maxAddressLines="2">
                            <template #country-content>
                                <InfoIconPopover :message="INFOICON_MESSAGE_CONTACT_FIELD" />
                            </template>
                            <template #state-content>
                                <InfoIconPopover :message="INFOICON_MESSAGE_CONTACT_FIELD" />
                            </template>
                            <template #top>
                                <div class="n-form-label mb-2">
                                    <label for="61d18332-3ce4-487f-bc66-b24b0e5a579b" class="label form-label">Ship To</label>
                                    <div class="n-text-box-field">
                                        <input id="61d18332-3ce4-487f-bc66-b24b0e5a579b"
                                               type="text"
                                               class="text-box form-control"
                                               :value="store.user.firstName + ' ' + store.user.lastName"
                                               disabled />
                                    </div>
                                </div>
                            </template>
                            <div class="mt-3">
                                <CheckBoxField v-model="form.model.saveAddress" @change="onOtherAddressChanged" label="Save this address for future orders" />
                                <CheckBoxField v-if="form.model.saveAddress"
                                               v-model="form.model.saveDefault"
                                               @change="onOtherAddressChanged"
                                               label="Save as my default shipping address" />
                            </div>
                        </EditShippingAddress>
                    </div>
                </div>
            </template>

            <template v-if="pageView === View.SUMMARY">
                <ShippingAddressTile v-if="isLoaded" :address="store.cart.current.shipToList[0]" :border="false"> </ShippingAddressTile>
            </template>
        </div>

        <div class="col-md-3">
            <CartBodyPanel class="cart-body-panel ship-method-panel">
                <template #title>
                    <h2>Ship Method</h2>
                </template>

                <ShipMethodSelector v-model="store.cart.current.shipMethodCode" :key="keyIndex" />
            </CartBodyPanel>
        </div>
    </div>
</template>

<style lang="scss">
    .ship-to-user {
        .n-tile-select-button {
            position: absolute;
            margin-top: -0.5rem;
            margin-left: -0.25rem;
        }

        .shipping-address-tile.selected .n-address-tile {
            border: 1px solid $color-1 !important;
        }
    }
</style>
