<script setup>
	// LimitMessages
	// Used to display limit message(s) that may apply to a given product.

	// Components ----

	// Imports ----
	import { defineProps, onMounted, watch } from 'vue';
	import { store } from '@/Store';

	// Props ----
	const props = defineProps({
		// The product whose limits to show.
		product: {
			type: Object,
			required: true
		}
	});

	// Handlers ----
	onMounted(() => {});

	watch(
		() => props.product.limits,
		async () => {
			await store.products.getProductLimits(props.product);
		},
		{ deep: true }
	);
</script>

<template>
	<div class="filter-messages">
		<div v-for="limit in props.product.limits" :key="limit.limitText">
			<i class="bi bi-lock"></i> Limit {{ limit.limitText }}
			<span v-if="limit.available !== null">({{ limit.available }} remaining)</span>
		</div>
	</div>
</template>

<style lang="scss">
	.filter-messages {
		font-size: 0.7rem;
	}
</style>
