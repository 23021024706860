
// ChangePasswordForm
// Implements the form that lets a user pick options for shipping to themselves.

// Imports ----
import { MessageType, Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';

export default class ChangePasswordForm extends Form {

    // Constructor
    constructor() {
        // Pass our model to the base class
        super(
            {
                oldPassword: '',
                password: '',
                confirmPassword: ''
            });

        // Set required fields
        this.validator.fields.oldPassword.isRequired = true;
        this.validator.fields.password.isRequired = true;
        this.validator.fields.confirmPassword.isRequired = true;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    onValidate() {
        super.onValidate();

        if (this.model.password !== '' && this.model.password !== this.model.confirmPassword) {
            this.validator.fields.password.addError('Passwords do not match');
        }
    }

    async onValidateAsync() {
        if (this.model.password && this.model.password.length >= 0) {
            const response = await store.authenticator.validatePassword(store.user.userName, store.user.email, this.model.password, this.model.confirmPassword);

            this.resetFieldValidators();

            response.messages.forEach(m => {
                if (m.messageType === MessageType.OK) {
                    this.validator.fields.password.addOk(m.userMessage);
                }
                else {
                    this.validator.fields.password.addError(m.userMessage);
                }
            });
        }
    }

    async onSubmit() {
        const response = await store.authenticator.changePassword(this.model.oldPassword, this.model.password);
        if (response.succeeded) {
            this.validator.addOk('Your password has been changed.');
            this.resetFields();
        } else {
            let message = 'Your password could not be updated.';
            if (response.messages.length > 0 && response.messages[0]?.userMessage) {
                message = response.messages[0]?.userMessage;
            }
            this.validator.addError(message);
        }
    }

    resetFields() {
        this.model.oldPassword = '';
        this.model.password = '';
        this.model.confirmPassword = '';
    }

    resetFieldValidators() {
        this.validator.fields.oldPassword.reset();
        this.validator.fields.password.reset();
        this.validator.fields.confirmPassword.reset();
    }
}
