<script setup>
	// App.vue
	// Standard app component for Nucleus sites

	// Imports ----
	import { onMounted, computed } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import { store } from '@/Store';
	import { TimeoutModal } from '@dd-nucleus/nucleus-vue';

	const router = useRouter();
	const route = useRoute();

	// Lifecycle ----
	onMounted(async () => {
		// Create an options object to pass in
		const options = {
			router: router,
			configUrl: 'api/site/config'
		};

		// Initialize the Nucleus app and supply the router
		store.initialize(options);
	});

	const inactiveTimer = Number(process.env.VUE_APP_INACTIVE_TIME_IN_SECONDS_TO_SIGNOUT);

	const routeKey = computed(() => {
		window.scrollTo(0, 0);
		return route.fullPath.split("#")[0]
	})
</script>

<template>
	<SizeDisplay />
	<StartupPanel />

	<!-- Track user activity and logs them out if they are inactive for specified time. The number indicated below is time which should be given in terms of seconds. -->
	<TimeoutModal :timer="inactiveTimer" />
	<!-- Note :key with route.fullPath wasnt working for sso. It was giving an infinite loop. Need to recheck this again with some good solution -->
	<router-view :key="routeKey" />
	<!--<router-view />-->
</template>

<style lang="scss">
	// Load main, which allows custom styles to be added after .vue components
	@import '@/scss/main.scss';
</style>
