<script setup>
    import { onMounted } from 'vue';
    import { store } from '@/Store';
    import { JwtAuthenticator } from '@dd-nucleus/nucleus-vue';
    import { useRoute, useRouter } from 'vue-router'

    onMounted(async () => {
        var route = useRoute();
        var router = useRouter();

        const auth = new JwtAuthenticator(store, 'api/veeva', router);
        const data = {
            encryptedToken: decodeURIComponent(route.query.token),
            source: route.query.source
        }
        const httpOptions = {
            headers: { 'content-type': 'application/json' }
        };

        await auth.signIn(data, httpOptions);
    });
</script>

<template>
    <VeevaPageTemplate>

    </VeevaPageTemplate>
</template>

<style>
</style>