<script setup>
	// HomePage
	// Main home page

	// Components ----
	import ProductGridTile from './components/ProductGridTile';
	import MockProductGridTile from './components/MockProductGridTile';

	// Imports ----
	import { computed, reactive } from 'vue';
	import { store } from '@/Store';
	import { Util } from '@dd-nucleus/nucleus-vue';
	import { Carousel } from '@dd-nucleus/nucleus-vue';

	// Filter used for the featured products panel
	const featuredFilter = computed(() => {
		// TODO: For now, returning no filter, because we have no idea what products are considered "featured" at this point.
		return store.products.filters.featured;
	});

	// Filter used to include only first order in bulkOrders in order search
	const includeFirstOrderInBulkOrdersFilter = {
		filterId: 'include-firstOrder-in-bulkOrders',
		values: ['']
	};

	let announcementToShow = reactive([]);
	let banners = reactive([]);

	store.onInitialized(async () => {
		if (store.user.userId) {
			const rolesToShow = store.user?.roles?.filter(t => t.forImpersonator === false);
			banners.push(...gettingSlides(rolesToShow));
			const response = await store.announcement.getAnnouncementsForUser();
			if (response.succeeded) {
				announcementToShow.push(...response.announcements);
			} else {
				this.store.handleApiError(response);
				return null;
			}
		}
	});

	function gettingSlides(roles) {
		if (!roles || roles.length === 0) return [];
		const azBBUBanner = '/images/az-bbu-banner.jpg';
		const azOBUBanner = '/images/az-obu-banner.jpg';
		const amgenBanner = '/images/amgen-banner.jpg';
		let slidesToShow = [];
		const rolesToAssign = ['AstraZeneca', 'Lighthouse', 'Alexion', 'Client.Admin', 'Qualfon.Csd', 'Qualfon.CsdAdmin', 'Qualfon.Support', 'SA'];
		const amgenSynosRoles = ['Amgen', 'Syneos'];
        slidesToShow.push(azOBUBanner);

		//TODO: Need to revist this logic of showing banners and logos
		if (roles.find(t => rolesToAssign.includes(t.id))) {
			if (roles.find(t => t.id === 'BBU')) {
				slidesToShow.push(azBBUBanner);
			}
		}
		if (roles.find(t => amgenSynosRoles.includes(t.id))) {
			if (roles.find(t => t.id === 'BBU') || roles.find(t => t.id === 'OBU')) {
				slidesToShow.push(amgenBanner);
			}
		}
		return slidesToShow;
	}

	//If the order provided is a bulk order then replace the order id and number with the bulk order id and number
	function updateOrderForBulkOrder(order) {
		if (order.bulkOrderId != null) {
			order.id = order.bulkOrderId;
			order.orderNumber = order.bulkOrderNumber;
		}

		return order;
	}

</script>

<template>
	<PageTemplate>
		<div class="container root-page">
			<div class="row banner">
				<div class="col">
					<Carousel :slides="banners" height="350px" :routeTo="'/products'" />
				</div>
			</div>
			<div class="row">
				<div class="col col-9">
					<div class="announcements" v-for="announcement in announcementToShow" :key="announcement.id">
						<h2 class="announcement-title" v-if="!!announcement.title">{{ announcement.title }}</h2>
						<p class="announcement-message" v-if="!!announcement.body" v-html="announcement.body"></p>
					</div>
					<BodyPanel class="home-body-panel">
						<template #title>
							FEATURED PRODUCTS
							<router-link to="/products">View All</router-link>
						</template>

						<SearchContainer search-id="products-for-user" sort-id="product-newest-to-oldest" model-id="tile" :display-count="12" :filters="featuredFilter">
							<SearchGrid>
								<template v-slot:grid="product">
									<ProductGridTile :product="product" />
								</template>

								<template #empty>
									<div>
										There are no featured products at the moment.
										<router-link to="/products">Click here</router-link> to search all products.
									</div>
								</template>

								<template #loading-grid>
									<MockSearchGrid>
										<MockProductGridTile />
									</MockSearchGrid>
								</template>
							</SearchGrid>
						</SearchContainer>
					</BodyPanel>
				</div>

				<div class="col col-3 side-bar">
					<SidePanel title="New Items">
						<SearchContainer search-id="products-for-user" sort-id="product-newest-to-oldest" model-id="row" :display-count="6" :filters="store.products.filters.new">
							<SearchResults>
								<template v-slot:result="product">
									<ProductLinkItem :product="product" />
								</template>

								<template #empty> No items are currently designated as New. </template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
								</template>
							</SearchResults>

							<ResultCountRangePanel :min-results="6">
								<div class="d-grid mt-3">
									<router-link to="/products" class="btn btn-color-1">SHOP ALL NEW ITEMS</router-link>
								</div>
							</ResultCountRangePanel>
						</SearchContainer>
					</SidePanel>

					<SidePanel title="Recent Orders">
						<SearchContainer
							search-id="orders-for-user"
							sort-id="order-newest-to-oldest"
							model-id="tile"
							:display-count="6"
							:filters="includeFirstOrderInBulkOrdersFilter">
							<SearchResults>
								<template v-slot:result="order">
									<div class="d-flex">
										<div class="flex-fill">
											<OrderLinkItem :order="updateOrderForBulkOrder(order)" />
										</div>
										<div class="text-end">
											{{ Util.date(order.orderDate) }}
										</div>
									</div>
								</template>

								<template #empty> No order history currently. </template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
								</template>
							</SearchResults>
						</SearchContainer>
					</SidePanel>

					<SidePanel title="My Favorite Items">
						<SearchContainer
							search-id="products-for-user"
							sort-id="product-newest-to-oldest"
							model-id="row"
							:display-count="6"
							:filters="store.products.filters.favorite">
							<SearchResults>
								<template v-slot:result="product">
									<ProductLinkItem :product="product" />
								</template>

								<template #empty> No items have been added to your Favorites. To add an item click on the favorite icon next to an item. </template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
								</template>
							</SearchResults>

							<ResultCountRangePanel :min-results="3">
								<div class="d-grid mt-3">
									<router-link to="/account/favorites" class="btn btn-color-1">VIEW ALL MY FAVORITES</router-link>
								</div>
							</ResultCountRangePanel>
						</SearchContainer>
					</SidePanel>
				</div>
			</div>
		</div>
	</PageTemplate>
</template>

<style lang="scss">
	.banner {
		margin-bottom: 2rem;
	}

	.announcements {
		margin: 10px 0;

		.announcement-title {
			color: #000000;
			margin: 0 0 5px 0;
			padding: 5px;
			width: fit-content;
		}

		.announcement-message {
			color: black;
			padding: 5px;
			border-radius: 2px;
			font-size: 14px;
			line-height: 20px;
			width: fit-content;

			p {
				margin: 0 !important;
			}
		}
	}

	.side-bar {
		.n-side-panel {
			margin-bottom: 2rem;
		}
	}

	.link-item {
		color: $color-1;
		opacity: 0.3;
		margin-bottom: 0.25rem;
	}

	.home-body-panel {
		.title {
			font-size: 0.9rem !important;
			border-bottom: 1px solid $gray-10 !important;
			font-weight: $bold !important;
			color: $gray-50 !important;
			background-color: $white !important;
			padding-left: 0rem !important;
			padding-bottom: 0.5rem !important;
			padding-top: 0rem !important;
		}
	}

	.home-body-panel .body {
		padding-top: 1rem !important;
		padding-left: 0rem !important;
		padding-right: 0rem !important;
	}

	.n-side-panel .body {
		min-height: 8rem;
		vertical-align: middle;
	}
</style>
