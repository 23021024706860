// Store
// Defines a class derived from NucleusStore that can customize services provided at the store level.

// Global Components ----
import PageTemplate from './template/PageTemplate';

// Pages for Router
import HomePage from './site/HomePage.vue';
import SignInPage from './site/areas/sign-in/SignInPage.vue';
import ADSignInPage from './site/areas/sign-in/azure/ADSignInPage.vue';
import ForgotPasswordPage from './site/areas/sign-in/ForgotPasswordPage.vue';
import VeevaPage from './site/areas/sign-in/VeevaPage.vue';
import HttpPostPage from './site/areas/sign-in/HttpPostPage.vue';
import SetPasswordPage from './site/areas/sign-in/SetPasswordPage.vue';
import ResetPasswordPage from './site/areas/sign-in/ResetPasswordPage.vue';
import ProductSearchPage from './site/areas/products/SearchPage.vue';
import ProductDetailPage from './site/areas/products/ProductDetailPage.vue';
import CartPage from './site/areas/cart/CartPage.vue';
import CheckoutPage from './site/areas/cart/CheckoutPage.vue';
import ReviewPage from './site/areas/cart/ReviewPage.vue';
import OrderConfirmedPage from './site/areas/cart/OrderConfirmedPage.vue';
import OrderDetailPage from './site/areas/orders/OrderDetailPage.vue';
import AccountPage from './site/areas/account/AccountPage.vue';
import HelpPage from './site/areas/help/HelpPage.vue';
import MFAPage from './site/areas/sign-in/MFAPage.vue'
import VerifyEmailPage from './site/areas/sign-in/VerifyEmailPage.vue';

// Client specific

// Imports ----
import { Announcement, NucleusStore, Notification} from '@dd-nucleus/nucleus-vue';
import { AspNetAuthenticator } from '@dd-nucleus/nucleus-vue';
import AddressList from './services/AddressList';
import ShippingMethodHelper from './services/ShippingMethodHelper';
import { Toast } from '@dd-nucleus/nucleus-vue';
import UserConfig from './services/UserConfig';

// Impersonation
import ImpersonationEnded from './site/components/ImpersonationEnded.vue';

export default class Store extends NucleusStore {
	// Constructor
	constructor() {
		super();

		// Set site to require authentication before seeing any pages
		this.requireAuthentication = true;

		this.addressList = new AddressList(this);
		this.authenticator = new AspNetAuthenticator(this);

		this.announcement = new Announcement(this);

		this.notification = new Notification(this);
		
		this.toast = new Toast(this);

		this.shippingMethodHelper = new ShippingMethodHelper(this);

		this.userconfig = new UserConfig(this);
		
		//Subscribe to the Sign out global event
		this.onSignedOut(() => {
			sessionStorage.removeItem('logged-in');
		});

		//Subscribe to the Forbidden request global event and redirect the user to the sign in route
		this.onForbidden(() => {
			let router = this.createVueRouter();
			const wasAuthenticated = this.user.isAuthenticated;
			const isAuthenticated = this.authenticator.isSignedIn();
			if (isAuthenticated && wasAuthenticated) {
				this.signOut(router).then(() => { router.go() });
			}
		});
	}

	userconfig;

	/**
	 * Initializes the store.
	 * @param {any} options
	 */
	async initialize(options) {
		await super.initialize(options);
	}

	// #region Public Methods ----

	// setupVue()
	// Called to allow this object to modify the Vue app if necessary.
	setupVue(vueApp) {
		super.setupVue(vueApp);

		// Add any statements here to modify the Vue application supplied

		// Register global components
		vueApp.component('PageTemplate', PageTemplate);
	}

	// createVueRouter()
	// Called to allow the store to add any needed routes. Use the helper methods as shown below.
	createVueRouter() {
		// Here, create the list of routes that apply specifically to this site.
		// NOTE: If authentication is required, defining a route to the path '/' will cause an error.
		//       In that case a route with name == 'Home' is also required, which is where users will land after signing in.
		this.addRoute('/home', 'Home', HomePage);
		this.addRoute('/products', 'ProductSearch', ProductSearchPage);
		this.addRoute('/product/:id', 'ProductDetail', ProductDetailPage);
		this.addRoute('/cart', 'Cart', CartPage);
		this.addRoute('/checkout/:section?', 'Checkout', CheckoutPage);
		this.addRoute('/review', 'Review', ReviewPage);
		this.addRoute('/order-confirmed/:id?', 'OrderConfirmed', OrderConfirmedPage);
		this.addRoute('/order/:id?', 'OrderDetail', OrderDetailPage);
		this.addRoute('/account/:section?', 'Account', AccountPage);
		this.addRoute('/help/:section?', 'Help', HelpPage);

		// Impersonation Pages
		this.addRoute('/impersonation-ended', 'ImpersonationEnded', ImpersonationEnded);

		// Sign-in
		this.addPublicRoute('/sign-in', 'SignIn', SignInPage);
		this.addPublicRoute('/sso/ad', 'ADSignIn', ADSignInPage);
		this.addPublicRoute('/sign-in/forgot-password', 'ForgotPassword', ForgotPasswordPage);
		this.addPublicRoute('/irep', 'iRep', VeevaPage);
		this.addPublicRoute('/http-post', 'HttpPost', HttpPostPage);
		this.addPublicRoute('/sign-in/reset-password/:id', 'ResetPassword', ResetPasswordPage);
		this.addPublicRoute('/sign-in/set-password/:id', 'SetPassword', SetPasswordPage);
		this.addPublicRoute('/mfa', 'MFA', MFAPage);
		this.addPublicRoute("/sign-in/verify-email", "VerifyEmail", VerifyEmailPage);

		// Client specific

		// Call the base class, supplying our routes, to actually create and configure the router.
		const router = super.createVueRouter();

		return router;
	}

	// Call Add Token API to generate new token and invalidate older ones if any.
	async addToken() {
		await this.api.post(`/api/asp-net-auth/add-token`);
	}

	// #endregion
}

export const store = new Store();
export const DEFAULT_COUNTRY_CODE = "US";
