`<script setup>
	// Imports ----
	import { defineProps, computed, ref } from 'vue';
	import { GeneralModal } from '@dd-nucleus/nucleus-vue';
	import VuePdfEmbed from 'vue-pdf-embed';

	// Props ----
	const props = defineProps({
		media: {
			type: Array,
			required: true,
			default: () => []
		}
	});

	// State ----
	const pdfModal = ref(false);
	const failedToLoad = ref(false);
	const pageCount = ref(1);
	const pdfRef = ref(null);
	const pdfUrl = ref('');
	const loadingPdf = ref(true);
	const customWidth = ref(800);

	// Computed ----
	const pdfMedia = computed(() => (props.media && props.media.filter(m => m.mediaType === 'Pdf')) || []);

	const hasPdf = computed(() => {
		return pdfMedia.value.length > 0;
	});

	const hasMultiplePdf = computed(() => {
		return pdfMedia.value.length > 1;
	});

	function previewPdf(url) {
		pdfModal.value.open();
		loadingPdf.value = true;
		failedToLoad.value = false;
		pdfUrl.value = url;
	}

	function closePdfModal() {
		pdfModal.value.close();
		loadingPdf.value = false;
		failedToLoad.value = false;
		pdfUrl.value = '';
		customWidth.value = 800;
	}

	function handleDocumentRender() {
		pageCount.value = pdfRef.value.pageCount;
	}

	function loadingFailed() {
		loadingPdf.value = false;
		failedToLoad.value = true;
	}

	function pdfLoaded() {
		loadingPdf.value = false;
		failedToLoad.value = false;
	}

	function loadingProgress() {
		loadingPdf.value = true;
		failedToLoad.value = false;
	}

	function zoomIn() {
		customWidth.value = customWidth.value + 50;
	}

    function zoomOut() {
        customWidth.value = customWidth.value - 50;
    }

</script>

<template>
	<div v-if="hasPdf" class="row mt-2 pdfcontrols">
		<general-modal ref="pdfModal">
			<div class="pdf-container">
				<div class="header">
					<p v-if="!loadingPdf && !failedToLoad">{{ pageCount }} Page(s)</p>
					<div class="zoom">
						<i @click="zoomIn" class="bi bi-zoom-in icon"></i>
						<i @click="zoomOut" class="bi bi-zoom-out icon"></i>
					</div>

					<button class="btn-close btn-close-white" aria-label="Close" @click="closePdfModal"></button>
				</div>
				<div class="loading-pdf" v-if="loadingPdf">
					<div class="spinner-border" role="status"></div>
					<p>Loading....</p>
				</div>
				<div class="loading-pdf-error" v-if="failedToLoad">Unable to load PDF. Please try again later.</div>
				<div v-if="pdfUrl" class="pdf-body">
					<vue-pdf-embed
						ref="pdfRef"
						:source="pdfUrl"
						:width="customWidth"
						@progress="loadingProgress"
						@loading-failed="loadingFailed"
						@loaded="pdfLoaded"
						@rendering-failed="loadingFailed"
						@rendered="handleDocumentRender" />
				</div>
			</div>
		</general-modal>
		<template v-if="hasMultiplePdf">
			<div class="col-12">
				<div>
					<button aria-expanded="false" data-bs-toggle="dropdown" class="mt-2 h-100 w-100 btn btn-outline-color-1 dropdown-toggle">PREVIEW</button>
					<ul class="dropdown-menu">
						<li v-for="pdf in pdfMedia" :key="pdf.id">
							<a class="dropdown-item" @click="() => previewPdf(pdf.url)">{{ pdf.name }}</a>
						</li>
					</ul>
				</div>
			</div>
		</template>

		<template v-else>
			<div class="col-12">
				<button class="w-100 btn btn-outline-color-1 mt-2" @click="() => previewPdf(pdfMedia[0].url)">PREVIEW</button>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
	.pdfcontrols {
		.loading-pdf {
			z-index: 10;
			position: fixed;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 100%;
			width: 80%;
			gap: 15px;

			p {
				font-size: 18px;
				margin-left: 10px;
			}
		}

		.loading-pdf-error {
			z-index: 10;
			position: fixed;
			width: 80%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			height: 100%;
		}
		.pdf-container {
			width: 80%;
			height: 100vh;
			margin: 0 auto;
			position: relative;
		}

		.header {
			background-color: rgba(0, 0, 0, 0.7);
			padding: 10px;
			position: fixed;
			text-align: center;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 2;
			height: 40px;

			button {
				cursor: pointer;
				margin: 0 10px 0 auto;
				position: absolute;
				top: 10px;
				right: 25px;
			}

			p {
				margin: 0 10px;
				color: white;
				font-size: 14px;
			}

			.zoom {
				position: absolute;
				margin: 0 10px 0 auto;
				top: 10px;
				right: 100px;
				display: flex;

				.icon {
					color: white;
					font-size: 14px;
					margin-left: 12px;
					cursor: pointer;
				}
			}
		}

		.pdf-body {
			width: 80vw;
			height: calc(100% - 40px); /* Adjust based on your header height */
			display: block;
			margin: auto;
		}
	}
</style>
