<script setup>

    // ShipToContacts
    // Allows the user to select multiple contacts as the ship-to, generating a bulk order.


    // Import ----
    import { ref, computed, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { store } from '@/Store';
    import { Tags, AMA_URL } from '@/constants';

    import ShipToContact from './ShipToContact';
    import ConfirmAddressList from '@/site/areas/cart/components/ConfirmAddressList';
    import CartBodyPanel from './CartBodyPanel';
    import ShipToUserForm from '../forms/ShipToUserForm';

    // Constants ----
    const Display = {
        SUMMARY_ADDRESS_LIST: 'SUMMARY_ADDRESS_LIST',
        PREVIEW_ADDRESS_LIST: 'PREVIEW_ADDRESS_LIST',
        CONFIRM_ADDRESS_LIST: 'CONFIRM_ADDRESS_LIST',
        UPLOAD_ADDRESS_LIST: 'UPLOAD_ADDRESS_LIST'
    };

    const router = useRouter();

    const AddOption =
    {
        NEW_CONTACT: 'NEW_CONTACT',
        FROM_CONTACTS: 'FROM_CONTACTS',
        UPLOAD_FILE: 'UPLOAD_FILE'
    };

    // const hcpOptions = ['Yes', 'No'];
    const hcpConfirmation = ref(false);
    const hcpConfirmationRecepient = ref(false);


    // State ----
    const display = ref(Display.UPLOAD_ADDRESS_LIST);
    const selectedOption = ref(AddOption.UPLOAD_FILE);
    const contactSelectedErrorMessage = ref('');
    const keyIndex = ref(0);
    const form = reactive(new ShipToUserForm());

    const selectedAddresses = ref([]);
    let showUploadFile = ref(false);

    const personalAddresses = computed(()=>{
        return selectedAddresses.value;
    })

    //computed

    const isContactsSelected = computed(() => {
      return selectedAddresses.value.length > 0;
    })
    const haveAddressesUpdated = ref(false);

    // Handlers ----
    store.cart.onCartLoaded(() => {
        update();
    });

    store.cart.onCartUpdated(() => {
        update();
    });

    function update() {
        selectedAddresses.value = store.cart.current.shipToList.filter(a => a.marketingContactId !== null && a.marketingContactId !== undefined).map((address) => {
            return {
                ...address,
                id: address.marketingContactId
            }
        });
    }

   async function onMyAddressConfirmClicked() {{
        // Pass only valid addresses
       // upload the address here
        await updatedAddresses(selectedAddresses.value);       
    }}

    function onCancelClicked() {
        display.value = Display.UPLOAD_ADDRESS_LIST;
        selectedOption.value = AddOption.UPLOAD_FILE;
        showUploadFile.value = false;
    }

    async function updatedAddresses(updatedAddresses) {
        haveAddressesUpdated.value = true;        
        let response = await store.cart.setShipToMultiplContacts(updatedAddresses);
        if (!response) {
            contactSelectedErrorMessage.value = 'Multi shipping is not supported across different countries and PO BOX !';
            display.value = Display.UPLOAD_ADDRESS_LIST;
        }
        else {
            contactSelectedErrorMessage.value = '';
            display.value = Display.CONFIRM_ADDRESS_LIST;
            let firstAddress = selectedAddresses.value[0];
            await store.shippingMethodHelper.updatesShippingMethods(firstAddress.addressLine1, firstAddress.countryCode);
        }
    }

    async function goToReviewPage(shipToContacts) {
        if (!haveAddressesUpdated.value) {
            await store.cart.setShipToMultiplContacts(shipToContacts);
        }

        if (shipToContacts.length == 1) {
            await updateToSingleContact(shipToContacts);
            router.push({ name: 'Review' });
            return;
        }

        if (await store.cart.updateMultiShipItemsQuantity()) {
            router.push({ name: 'Review' });
        }
    }

    async function updateToSingleContact(shipToContacts) {
        const shipTo = shipToContacts[0];
        form.model.addressee = shipTo.addressee;
        form.model.companyName = shipTo.companyName;
        form.model.addressLine1 = shipTo.addressLine1;
        form.model.addressLine2 = shipTo.addressLine2;
        form.model.addressLine3 = shipTo.addressLine3;
        form.model.addressLine4 = shipTo.addressLine4;
        form.model.city = shipTo.city;
        form.model.state = shipTo.state;
        form.model.postalCode = shipTo.postalCode;
        form.model.countryCode = shipTo.countryCode;
        await store.cart.setShipToModeToSingleContact()
        await form.submit(false);
    }

    function onAddressSelected(address) {
        if (address.addressee.length < 1 && address.firstName && address.lastName) {
            address.addressee = address.firstName + " " + address.lastName;
        }
        selectedAddresses.value.push(address);
    }

    function onAddressDeselected(address) {
        selectedAddresses.value = selectedAddresses.value.filter(x => x.id !== address.id);
    }

    const hasHCPItems = computed(() => {
        let result = false
        for (const item of store.cart.current.items) {
            const tags = item.product.tags;
            if (tags.map(tag => tag.name).includes(Tags.HCP)) {
                result = true;
                break;
            }
        }
        return result
    })

    const isReviewDisabled = computed(() => {
        if (!hasHCPItems.value) {
            return false;
        }

        if (hcpConfirmation.value && hcpConfirmationRecepient.value) {
            return false;
        }
        return true;
    })

   
</script>

<template>
    <div class="row mb-2 invalid-file" v-if="contactSelectedErrorMessage">
        <div class="invalid-file-message p-2">
            <span>{{ contactSelectedErrorMessage }}</span>
        </div>
    </div>
    <div class="ship-to-contacts">
        <div class="row" v-if="display === Display.CONFIRM_ADDRESS_LIST">
            <div class="col-md-9">
                <div >
                    <ConfirmAddressList :addressList="personalAddresses" @update="updatedAddresses">
                        <template #empty>
                            Empty Addresses
                        </template>
                    </ConfirmAddressList>

                    <div v-if="hasHCPItems">
                        <div class="hcp">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="hcpConfirmation">
                                <label class="form-check-label  label-text-font-size" for="flexCheckDefault">
                                    Please confirm, if this order is being placed to HCP
                                </label>
                            </div>
                        </div>

                        <div v-if="hcpConfirmation" class="hcp-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="hcpConfirmationRecepient">
                                <label class="form-check-label  label-text-font-size" for="flexCheckDefault">
                                    Please confirm, if selected address is not in this <a :href="AMA_URL" target="_blank">AMA List</a>
                                </label>
                            </div>
                        </div>
                    </div>



                    <div class="show-right">
                        <PageButton class="me-2" :outline="true" @click="onCancelClicked">Back</PageButton>
                        <PageButton class="me-2" :disabled="personalAddresses.length === 0 || isReviewDisabled" @click="goToReviewPage(personalAddresses)">Review</PageButton>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <CartBodyPanel class="cart-body-panel ship-method-panel">
                    <template #title>
                        <h2>Ship Method</h2>
                    </template>

                    <ShipMethodSelector v-model="store.cart.current.shipMethodCode" :key="keyIndex" />
                </CartBodyPanel>
            </div>
        </div>
        

        <template v-else>
            <ShipToContact :can-edit="true" :multiSelect="true" :toggle="true" @selected="onAddressSelected" @deselected="onAddressDeselected" />
            <div class="show-right">
                <PageButton class="me-2" :disabled="!isContactsSelected" @click="onMyAddressConfirmClicked">Confirm Addresses</PageButton>
            </div>
        </template>
    </div>

</template>

<style lang="scss" scoped>

    .invalid-file {
        padding: 6px 12px;
    }

    .invalid-file-message {
        display: inline-grid !important;
        color: red !important;
        border: 1px solid red !important;
    }

    .ship-to-contacts {
        .add-contacts {
            .sub-title {
                font-weight: $bold;
            }
        }

        .show-right {
            float: right
        }


        .add-options {
        }

        .add-option {
            border: 1px solid $gray-20;
            padding: 0.5rem;
            height: 10rem;
        }

        .hcp {
            margin-top: 1.125rem;

            .header {
                font-size: 1rem;
                font-weight: bold;

                span {
                    color: red;
                }
            }

            .content {
                font-size: 0.8rem;
            }
        }

        .hcp-2 {
            margin-top: 16px;

            .header {
                font-size: 1rem;
                font-weight: bold;
            }

            .content {
                font-size: 0.8rem;
            }

            .yes {
                font-size: 1rem;
                font-weight: bold;
                color: green
            }

            .no {
                font-size: 1rem;
                font-weight: bold;
                color: red;
            }
        }

        .hcp, .hcp-2 {

            .label-text-font-size {
                font-size: 1rem !important;
            }

            .radio-group {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .radio-label {
                display: flex;
                align-items: center;
                margin-right: 20px;
            }

            .radio-input {
                margin-right: 5px;
            }

            .radio-text {
                font-size: 16px;
            }
        }
    }


</style>