
// ShipToUserForm
// Implements the form that lets a user enter a custom shipping address.

// Imports ----
import { ShippingAddressForm } from '@dd-nucleus/nucleus-vue';
import { store, DEFAULT_COUNTRY_CODE } from '@/Store';

export default class ShipToUserForm extends ShippingAddressForm {

    // Constructor
    constructor(addressConfig = null) {
        // Pass our model to the base class
        super(
            {
                saveAddress: false,            // Save to user's addresses?
                saveDefault: false             // Save as user's default shipping address?
            }, null, addressConfig);
        
        this.model.countryCode = this.model.countryCode ? this.model.countryCode : DEFAULT_COUNTRY_CODE;
        this.validator.fields.addressLine1.noPoBox = false;
        // If the cart isn't yet loaded, initialize the model when it is; otherwise, initialize now.
        if (typeof store.cart.current.shipToList === 'undefined') {
            store.cart.onCartLoaded(() => {
                this.load()
            });
        }
        else {
            this.load();
        }
    }

    load() {
        // Only copy values if the address ID is null, and we're in the right ship-to mode
        if (store.cart.shipToMode === store.cart.ShipToMode.ShipToUser) {
            const address = store.cart.current.shipToList[0];
            if (address.contactAddressId === null)
                this.model = Object.assign(this.model, address);
        }
    }

    onValidate() {
        super.onValidate();
    }

    async onSubmit() {
        // Copy model, remove properties not applicable to the API call.
        const address = Object.assign({}, this.model);
        delete address.saveAddress;
        delete address.saveDefault;
        address.contactId = store.user.contactId;

        await store.cart.setShipToUserCustomAddress(address, this.model.saveAddress, this.model.saveDefault);
    }
}