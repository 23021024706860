<script setup>
	// CheckoutPage
	// Allows the user to view and change the shipping information for the cart.

	// Components ----
	import CartBodyPanel from './components/CartBodyPanel';
	import ShipTo from './components/ShipTo';
	// Imports ----
	import { ref, computed } from 'vue';
	import { store } from '@/Store';
	import { useRouter } from 'vue-router';

	const continueStatus = ref(false);
	const show = ref(false);
    const validAddress = ref(true);

	const router = useRouter();
	

	function checkContinueStatus(type) {
		if (type.addValue === 'SAVED') {
			type.addressId ? (continueStatus.value = true) : (continueStatus.value = false);
		} else {
			type.formIsValid ? (continueStatus.value = true) : (continueStatus.value = false);
		}
	}

	function isValidAddress(valid) {
        show.value = true;
        validAddress.value = !valid;
	}

	const showInvalidAddressError = computed(() => {
        return show.value && !validAddress.value
	})

    function review() {
        router.push({ name: 'Review' });
    }

</script>

<template>
	<PageTemplate>
		<div class="container root-page checkout-page">
			<div class="float-end">
				<PageButton class="me-2" :outline="true" url="/cart">Back to Cart</PageButton>
				<button class="btn btn-color-1" v-if="!store.cart.isMultiShip()" @click="review" :disabled="!continueStatus || showInvalidAddressError">
					Continue
				</button>
			</div>
			
			<h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Shipping</span></h1>
			
			<div v-if="showInvalidAddressError" class="col-9 address-warning">
				<p>Please note you do not have a valid shipping address in your profile. Please go to My Profile, Add Shipping Address and save as the Default address. If you need assistance please send an email to <a href="mailto:AstraZenecaOPCareTeam@qualfon.com">AstraZenecaOPCareTeam@qualfon.com</a>.</p>
			</div>

			<div class="row">
				<div class="col-12">
					<CartBodyPanel class="cart-body-panel">
						<template #title>
							<h2>Ship To</h2>
						</template>

						<ShipTo :editable="true" :editing="true" @addressType="checkContinueStatus" @invalidAddress="isValidAddress" />
					</CartBodyPanel>
				</div>
			</div>
		</div>
	</PageTemplate>
</template>

<style lang="scss">
	.root-page {
		min-height: 30rem;
	}

    .checkout-page {
        .continue-btn {
            outline: none;
            padding: 0;
            border: none;

            .disabled-continue {
                color: white;
                background-color: #0000eb;
                border-color: #0000eb;
                opacity: 0.65;
            }
        }

        .n-body-panel {
            .body {
                padding: 0.75rem 0rem;
            }
        }

        .ship-method-panel {
            .body {
                padding-left: 1.5rem;
            }
        }

        .address-warning {
            background-color: #fff;
            padding: 8px;
            margin: 0px;
            p {
                font-size: .85rem;
                margin: 0px;
				color:#ff0000
            }
        }
    }
</style>
