<script>
    // CartItemList
    // A list of cart items in either edit or read-only mode.

    // Components ----
    import CartItemTile from './CartItemTile';
</script>

<script setup>
    // Imports ----
    import { defineProps, defineEmits } from 'vue';
    import { store } from '@/Store';

    const emits = defineEmits(['quantityChanged'])


    // Props ----
    const props = defineProps({
        // Indicates whether this list is considered editable
        editable: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // Methods ----
    function getCssClass(index) {
        return { shaded: index % 2 > 0 };
    }

    function emitHandler(value) {
        emits('quantityChanged', value)
    }
    // Handlers ----
</script>

<template>
    <div class="cart-item-list">
        <template v-if="store.cart.current.items.length > 0">
            <div v-for="(item, index) in store.cart.current.items" :key="item.id" class="cart-item-container" :class="getCssClass(index)">
                <CartItemTile @quantityChanged="emitHandler" :item="item" :editable="props.editable" />
            </div>
        </template>
        <div v-else>
            <slot name="empty"></slot>
        </div>
    </div>
</template>

<style lang="scss">
    .cart-item-list {
        .cart-item-container {
            margin-bottom: 0.5rem;
            padding: 0.5rem;
        }

        .cart-item-tile:last-child .inner-row {
            border-bottom: 0px;
        }
    }
</style>
