<script setup>
	// OrderHistoryTile
	// A tile used for each order in order history.

	// Components ----

	// Imports ----
	import { defineProps } from 'vue';
	import { Util } from '@dd-nucleus/nucleus-vue';
	import OrderStatus from './OrderStatus.vue';

	// Props ----
	defineProps({
		// The order whose information to show.
		// API model: DD.Nucleus.Storefront.Api.OrderTileModel
		order: {
			type: Object,
			required: true
		}
	});

	// Computed ----

	// Methods ----
	function shipToText(order) {
		let text = '';

		if (Util.isNotEmpty(order.shipTo.addressee)) text += order.shipTo.addressee;

		else text += order.shipTo.attention;

		if (Util.isNotEmpty(order.shipTo.companyName)) text += ' - ' + order.shipTo.companyName;

		if (Util.isNotEmpty(order.shipTo.addressLine1)) text += ', ' + order.shipTo.addressLine1;

        if (Util.isNotEmpty(order.shipTo.addressLine2)) text += ', ' + order.shipTo.addressLine2;

        if (Util.isNotEmpty(order.shipTo.addressLine3)) text += ', ' + order.shipTo.addressLine3;

        if (Util.isNotEmpty(order.shipTo.addressLine4)) text += ', ' + order.shipTo.addressLine4;

		if (Util.isNotEmpty(order.shipTo.city)) text += ', ' + order.shipTo.city;

        if (Util.isNotEmpty(order.shipTo.state)) text += ', ' + order.shipTo.state;

        if (Util.isNotEmpty(order.shipTo.province)) text += ', ' + order.shipTo.province;

        if (Util.isNotEmpty(order.shipTo.postalCode)) text += ', ' + order.shipTo.postalCode;

		if (Util.isNotEmpty(order.bulkOrderNumber)) text = '';

		return text;
	}
</script>

<template>
	<div class="order-history-tile d-flex">
		<div class="flex-fill">
			<div class="title" v-if="order.bulkOrderNumber">
				<span class="order-number">Order #{{ order.bulkOrderNumber }}</span> | {{ Util.date(order.orderDate) }}
			</div>
			<div class="title" v-else>
				<span class="order-number">Order #{{ order.orderNumber }}</span> | {{ Util.date(order.orderDate) }}
			</div>

			<div class="shipto">{{ shipToText(order) }}</div>
			<div v-if="order.shipments.length > 0" class="shipments">
				<div v-for="shipment in order.shipments" :key="shipment.id" class="shipment">
					Shipped {{ Util.date(shipment.shipDate) }} <span v-if="Util.isNotEmpty(shipment.trackingUrl)"></span>:
					<a :href="shipment.trackingUrl" target="_blank">{{ shipment.trackingNumber }}</a>
				</div>
			</div>

			<div><OrderStatus :order="order" /></div>
			<div class="status" v-if="order.bulkOrderNumber"><span>Multi-Address Order</span></div>
		</div>
		<div class="text-end action-panel">
			<router-link v-if="order.bulkOrderNumber" :to="'/order/' + order.bulkOrderId">View Details</router-link>
			<router-link v-else :to="'/order/' + order.id">View Details</router-link>
		</div>
	</div>
</template>

<style lang="scss">
	.order-history-tile {
		display: flex;
		gap: 2rem;

		button {
			max-height: 1.5rem;
			padding: 0 10px;
		}
		.order-number {
			font-weight: $bold;
		}

		// TODO: This is hacked in for now. Look at global link styling.
		.action-panel a,
		.action-panel a:visited {
			color: $color-2;
			text-decoration: none;
		}

		.action-panel a:hover {
			text-decoration: underline;
		}

		.shipments .shipment {
			color: $ok;
			font-weight: bold;
			font-size: 0.7rem;
			margin-top: 0.5rem;
		}
	}
</style>
