<script setup>

    // OrderShipToMultiple
    // Displays ship-to information when using multiship

    // Imports ----
    import { defineProps, ref } from 'vue';

    // Components ----
    import MultiShipDetails from './MultiShipDetails';
    import OrderStatus from '@/site/components/OrderStatus.vue'

    // Props ----
    const props = defineProps({
        // The order whose ship-to information to show
        order: {
            type: Object,
            required: true
        }
    });

    // Computed ----

    // State ----
    const isOpen = ref(false);

    const removeAddressLine3 = function (shipTo) {
        delete shipTo.addressLine3
        return shipTo;
    };
    // Methods ----
    function expandAll() {
        isOpen.value = true;
    }

    function collapseAll() {
        isOpen.value = false;
    }



</script>

<template>

    <div class="order-ship-to-multiple">



        <div class="d-flex mb-3">
            <div>
                <h2>Shipping Address & Quantities</h2>
            </div>
            <div class="ms-auto">
                <a v-if="!isOpen" href="#" class="fs-2" @click="expandAll">Expand All</a>
                <a v-else href="#" class="fs-2" @click="collapseAll">Less Details</a>
            </div>
        </div>


        <div class="accordion accordion-flush" v-for="(ship,index) in props.order" :key="index">

            <div class="accordion-header" :id="'flush-heading'+index">


                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+index" aria-expanded="false" :aria-controls="'flush-collapse-'+index">

                    <div class="flex-fill">
                        <AddressTile :address="removeAddressLine3(ship.shipTo)" :border="false" />
                    </div>
                    <div class="flex-end m-2">
                        <span>Order # {{ ship.orderNumber }} </span> &nbsp; | &nbsp; <OrderStatus :order="ship" />
                    </div>


                </button>
            </div>

            <div :id="'flush-collapse'+index" :class="{ show: isOpen }" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+index" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <MultiShipDetails :order="ship" />
                </div>
            </div>

        </div>

    </div>




</template>

<style lang="scss">

    .order-ship-to-multiple {

        .accordion-button:not(.collapsed) {
            color: unset;
            background-color: unset;
            box-shadow: unset;
        }

        .accordion-button {
            background: unset;
            display: flex;
            justify-content: space-between;
        }

        .accordion:nth-child(even) {
            background: $neutral-lt;
        }

        .ship-type-title {
            font-size: 0.9rem;
            font-weight: $bold;
        }

        .status {
            margin-top: 0.5rem;
            font-weight: $bold;
            font-size: 0.7rem;
        }

        .status.pending {
            .bi {
                opacity: 0.5;
                color: $ok;
            }
        }

        .status.inprogress {
            .bi {
                opacity: 0.5;
                color: $ok;
            }
        }

        .status.pending-approval {
            .bi {
                color: $warning;
            }
        }

        .status.canceled {
            .bi {
                color: $error;
            }
        }

        .status.completed {
            .bi {
                color: $ok;
            }
        }
    }
</style>