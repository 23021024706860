<script>
	// ProductGridTile
	// A tile for a single product intended to be displayed in grid mode.

	// Setup ---
	// Use the values below to modify how the tile appears
	const setup = {
		tileHeight: '27rem', // Height of the full tab, fixed for alignment purposes
		productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
	};

	export const Setup = setup;
</script>

<script setup>
	// Components ----
	import LimitMessages from './LimitMessages';
	import ConferenceOrderTile from '@/site/components/ConferenceOrderTile'
	// Imports ----
	import { computed, reactive, defineProps, ref, watch } from 'vue';
	import { AddToCartForm } from '@dd-nucleus/nucleus-vue';
	import ProductPdfControls from './ProductPdfControls.vue';
	import { store } from '@/Store';	
    import { STOCK_EXCEED_MESSAGE } from '@/constants';

	// Props ----
	const props = defineProps({
		// The product whose information to show.
		// API model: DD.Nucleus.Storefront.Api.ProductTileModel
		product: {
			type: Object,
			required: true
		}
	});

	// State ----
	const product = reactive(props.product);
    const form = reactive(new AddToCartForm(props.product, null, STOCK_EXCEED_MESSAGE));

	// Computed ----
	const showInStockQuantity = computed(() => {
		return product.limits == null || product.limits.length == 0;
	});

	const quantityLabel = computed(() => {
		return store.config.store.unitOfMeasures.find(unit => unit.id?.toLowerCase() === product.unitOfMeasure?.toLowerCase())?.text ?? null
	})

    const customUnit = computed(() => {
        if (!store?.userconfig?.isUomToBeBroken) return ""

        return store?.userconfig?.defaultUnitOfMeasure;
	})

    const conferenceType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

    store.cart.onCartLoaded(() => {
        conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

    store.cart.onCartUpdated(() => {
        conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

	const hasLimitExceeded = ref(false)

	const hasStockExceeded = ref(false)

	watch(() => form.model, () => {
		hasLimitExceeded.value = form.limits?.length > 0 ?? false;
        hasStockExceeded.value = form.product.quantityAvailable < form.model.quantity ? true : false;
    }, { deep: true, immediate: true });

	// Handlers ----
</script>

<template>
	<div class="product-grid-tile d-flex flex-column" :style="{ minHeight: setup.tileHeight }">
		<div class="top">
			<ProductThumbnail :product="product" height="200px" no-image-available-url="/images/no-image.png" />
		</div>
		<div class="middle flex-fill">
			<!-- Stretchy middle part-->

			<div class="d-flex flex-row">
				<div class="flex-fill">
					<ProductTags :product="product" />
				</div>
				<div>
					<ProductFavoriteIcon :product="product" />
				</div>
			</div>

			<!-- Product name and item number -->
			<router-link class="title-link" :to="`/product/${product.id}`">
				<div class="name" :title="product.name">
					{{ product.name }}
				</div>
			</router-link>
			<div class="item-number">
				<router-link class="title-link" :to="`/product/${product.id}`">{{ product.itemNumber }}</router-link>
			</div>

			<LimitMessages :product="product" class="mt-2" />

			<span v-if="showInStockQuantity" class="filter-messages">Qty in stock: {{ product.quantityAvailable }} </span>
		</div>
		<div class="bottom d-flex coming-soon" v-if="product.isAvailableSoon">
			<i class="bi bi-hourglass-split"></i>
            <i><p>Coming soon...</p></i>
		</div>
		<div class="bottom" v-else>
			<!-- Fixed bottom part -->
			<MessageList :messages="product.messages" :add-box="false" />
			<p v-if="product.quantityPerUnit > 0 && !store?.userconfig?.isUomToBeBroken">Quantity: {{ product.quantityPerUnit }} {{ product.quantityPerUnit > 1 ? 'units' : 'unit' }} per {{ quantityLabel }}</p>
			<p v-if="store?.userconfig?.isUomToBeBroken">
				Quantity: 1 unit / {{ store?.userconfig?.defaultUnitOfMeasure }}
			</p>
			<FormContainer :form="form" v-if="product.canAddToCart">
				
					<FormMessages :showOnlySuccessMessagesInStock="conferenceType ? true : false"  field-name="quantity" :add-box="false" class="mb-2" />
				
				<FieldMessages field-name="quantity" class="mb-2" />
				
				<ProductAddToCartQty :product="product" :unit="customUnit" />
				<ConferenceOrderTile v-if="form.validator.isInvalid && !conferenceType && hasLimitExceeded && !hasStockExceeded" title="Conference Order" />
				
				<div class="d-grid mt-3">
					<button class="btn btn-outline-color-1" @click="form.submit(validate=!conferenceType)" :disabled="conferenceType ? hasStockExceeded : form.status.isInvalid">
						ADD TO CART 
					</button>
				</div>
				<ProductPdfControls :media="product.media" />
			</FormContainer>
		</div>
	</div>
</template>
<style lang="scss">
	.product-grid-tile {

		.coming-soon {
			justify-content: center;
			align-self: center;

			.bi-hourglass-split {
				font-size: 1rem;
				margin-right: 0.5rem;
			}

			p {
				font-size: 14px;
				margin-bottom: 0;
			}
		}

		p {
			font-size: 12px;
		}
		.name {
			font-size: 1rem;
			line-height: 1.1rem;
			height: 2.2rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		a.title-link,
		a.title-link:visited,
		a.title-link:hover {
			color: var(--bs-body-color);
			text-decoration: none;
		}
		.item-number a.title-link {
			font-size: 0.7rem;
			color: $gray-50;
		}
		.n-product-add-to-cart-qty .text-box {
			border-color: #dddddd;
		}
		.btn[disabled] {
			opacity: 0.2;
		}
	}
</style>
