<script setup>
	// CartPage
	// Shows the current contents of the cart.

	// Components ----
	import CartItemList from './components/CartItemList';
	import ConferenceOrderTile from '@/site/components/ConferenceOrderTile'
	import CartBodyPanel from '@/site/areas/cart/components/CartBodyPanel';

	// Imports ----
	import { useRouter } from 'vue-router';
	import { store } from '@/Store';
	import { computed, onMounted, reactive } from 'vue';
	import { UserRole } from '@/constants';
	import { Tags } from '@/constants';

	const router = useRouter();

	// State ----
	const productLimitsHash = reactive({ data: {} });

	onMounted(() => {
		initProductLimits()
	})

	store.cart.onCartLoaded(() => {
		initProductLimits()
	})

	store.cart.onCartUpdated(() => {
		initProductLimits()
	});

	function initProductLimits() {
		// Create a new object
		let newProductLimitHashData = {};

		// For each item in the hash
		Object.keys(productLimitsHash.data).forEach((id) => {
			// If the item is present in the cart, add it to the new hash.
			if (store.cart.current.items.some(item => item.product.id === id)) {
				newProductLimitHashData[id] = productLimitsHash.data[id];
			}
		});

		// For each item in the cart
		store.cart.current.items.forEach((item) => {
			// If the item is not in the existing hash, add it to the new hash.
			if (!Object.prototype.hasOwnProperty.call(productLimitsHash.data, item.product.id)) {
				newProductLimitHashData[item.product.id] = { hasLimitExceeded: false, hasStockExceeded: false };
			}
		});

		productLimitsHash.data = {};

		productLimitsHash.data = newProductLimitHashData;

	}

	// Handlers ----
	function onCheckout() {
		router.push({ name: 'Checkout' });
	}

	const hasConferenceRole = computed(() => {
		return store.user.roles?.filter(role => role.id == UserRole.ConferenceOrderRole)?.length > 0;
	});

	function productLimitChanged(productLimits) {
		// Check if productLimits.productId exists in productLimitsHash.data
		if (Object.prototype.hasOwnProperty.call(productLimitsHash.data, productLimits.productId)) {
			// If it exists, update the hasLimitExceeded and hasStockExceeded fields
			productLimitsHash.data[productLimits.productId].hasLimitExceeded = productLimits.hasLimitExceeded;
			productLimitsHash.data[productLimits.productId].hasStockExceeded = productLimits.hasStockExceeded;
		}
	}

	const isLimitExceeded = computed(() => {
		return Object.values(productLimitsHash.data)?.filter((item) => item.hasLimitExceeded === true)?.length > 0
	})

	const isStockExceeded = computed(() => {
		return Object.values(productLimitsHash.data)?.filter((item) => item.hasStockExceeded === true)?.length > 0
	})

	const isConferenceOrderType = computed(() =>  store.cart.current.attributes?.allowLimitOverride === "true");

	const isCartDisabled = computed(() => {


		if (isStockExceeded.value || !store.cart.current.canPlaceOrder || (isLimitExceeded.value && !isConferenceOrderType.value))
			return true

		return false
	})

	const HCPOrPatItemsQuantity = computed(() => {
		let quantity = 0
		for (const item of store.cart.current.items) {
			const tags = item.product.tags;
			if (tags.map(tag => tag.name).includes(Tags.HCP)) {
				quantity += 1
			}
		}
		return quantity;
	})

	const isHCPOrPATCheckoutDisabled = computed(() => {
		if (HCPOrPatItemsQuantity.value === 0) {
			return false
		}
		if (HCPOrPatItemsQuantity.value != store.cart.current.items.length) {
			return true
		}
		return false
	})

</script>

<template>
	<PageTemplate>
		<div class="container root-page cart">
			<div v-if="store.cart.current.items.length > 0" class="float-end">
				<router-link class="btn btn-outline-color-1 me-2" to="/products">CONTINUE SHOPPING</router-link>
				<button class="btn btn-color-1" @click="onCheckout" :disabled="isCartDisabled || isHCPOrPATCheckoutDisabled">CHECKOUT</button>
			</div>
			<h1 class="cart-page-header mb-4">Shopping Cart</h1>

			<div v-if="HCPOrPatItemsQuantity != store.cart.current.items.length && HCPOrPatItemsQuantity > 0" class="col-9 hcp-warning">
				<p>Your shopping cart includes both HCP and NON HCP items. Please update the items in your cart so that they are either all HCP ONLY items or all NON HCP items before proceeding to the checkout page.</p>
			</div>

			<div class="row">
				<div class="col-9">
					<CartItemList @quantityChanged="productLimitChanged" class="mt-5" :editable="true">
						<template #empty>
							<div class="text-center">
								Your cart is currently empty.<br /><br />
								<router-link class="btn btn-outline-color-1 me-2" to="/products">CONTINUE SHOPPING</router-link>
							</div>
						</template>
					</CartItemList>
				</div>
				<div class="col-3" v-if="hasConferenceRole">
					<div class="summary mt-5">
						<CartBodyPanel class="cart-body-panel">
							<template #title>
								<h2>Order Summary</h2>
							</template>

							<ConferenceOrderTile title="Please confirm, if this is a Conference Order" />
						</CartBodyPanel>

					</div>
				</div>
			</div>

		</div>
	</PageTemplate>
</template>

<style lang="scss">
	.cart {
		.summary {
			border: 2px;

			.title {
				border-bottom: solid 2px #f8f8f8;
			}
		}

		.hcp-warning {
			background-color: #e9eef8;
			padding: 8px;
			margin: 0px;

			p {
				font-size: 1rem;
				margin: 0px;
			}
		}
	}
</style>
