<script setup>

    // ProfilePanel
    // Shows the profile panel of the my account area.

    // Components ----
    import { PasswordHints, MessageType } from '@dd-nucleus/nucleus-vue';

    // Imports ----
    import { computed, ref } from 'vue';
    import ChangePasswordForm from '../forms/ChangePasswordForm';
    import { useRouter } from 'vue-router';

    // State ----
    const form = new ChangePasswordForm();
    const changing = ref(false);
    const isValidatingPassword = ref(false);
    const router = useRouter();
    // Handlers ----
    function onChangePassword() {
        changing.value = true;
    }

    function onCancel() {
        isValidatingPassword.value = false;
        changing.value = false;
        router.push({ path: '/' });
    }

    function onPasswordTextChange() {
        // TODO: Is there a debounce utility available?
        isValidatingPassword.value = true;
    }

    async function onSave() {
        await form.submit();

        changing.value = false;
        isValidatingPassword.value = false;
    }

    // Computed ----
    const allPasswordConditionsMet = computed(() => {
        return form.validator.fields.password.messages.every(m => m.type === MessageType.OK);
    });

    const showPasswordRequirements = computed(() => {
        if (allPasswordConditionsMet.value) {
            return false;
        }

        return (isValidatingPassword.value) && (form.model.password.length >= 0);
    });

</script>

<template>
    <div class="account-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Password</span></h1>

        <button v-if="changing" class="btn btn-color-2" @click="onChangePassword">Change Password</button>

        <div v-else class="col-12 col-md-8 col-lg-6">
            <FormContainer :form="form">
                <FormMessages class="mb-2" />


                <div class="row">

                    <div class="col-md-7 col-lg-8 test ">
                        <div class="mb-3">
                            <FieldLabel field-name="oldPassword" label="Current Password">
                                <TextBoxField v-model="form.model.oldPassword" placeholder="Current Password" :is-password="true" show-pwd-btn-class="btn-color-2-xlt" />
                            </FieldLabel>
                        </div>
                        <div class="mb-3">
                            <FieldLabel field-name="password" label="New Password">
                                <TextBoxField v-model="form.model.password" placeholder="Password" :is-password="true" @keyup="onPasswordTextChange" show-pwd-btn-class="btn-color-2-xlt" />
                                <PasswordHints />
                            </FieldLabel>
                        </div>
                        <div class="mb-3">
                            <FieldLabel field-name="confirmPassword" label="Confirm Password">
                                <TextBoxField v-model="form.model.confirmPassword" placeholder="Confirm Password" :is-password="true" show-pwd-btn-class="btn-color-2-xlt" />
                            </FieldLabel>
                        </div>
                    </div>
                    <div class="col-md-5 col-lg-4">
                        <div class="error-label-panel" v-if="showPasswordRequirements">
                            <div class="password-requirements-box">
                                <b>Password Requirements</b>
                                <FieldMessages field-name="password" :add-box="false" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 pt-2">
                    <button class="btn btn-outline-color-2 me-2" @click="onCancel">Cancel</button>
                    <button class="btn btn-color-2" :disabled="form.validator.isInvalid" @click="onSave">Save</button>
                </div>
            </FormContainer>
        </div>

    </div>
</template>

<style lang="scss">
    .password-requirements-box {
        margin-top: 0.25rem;
    }

    div.error-label-panel {
        position: absolute;
        background: #fff;
        border: 1px solid;
        border-color: #b63f29 !important;
        border-radius: 3px;
        padding: 10px;
        width: 200px;
        z-index: 200;
        margin-top: 90px !important;
    }

    div.error-label-panel:before {
        content: '';
        right: 100%;
        top: 18%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 7px solid transparent;
        border-right-color: #b63f29;
    }

    .n-message {
        display: flex !important;
    }
</style>
