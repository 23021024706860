<script setup>

    //  UploadAddressList
    // This will accept a drag and drop of file or a select file box that can upload multiple files.


    // Constants
    const fileFormats = {
        XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        CSV: 'text/csv'
    }

    // Imports
    import { ref, defineEmits } from 'vue';
    import { store } from '@/Store';

    // State
    const active = ref(false);
    const isInvalidFileType = ref(false);
    const fileName = ref('');
    const hasFileUploaded = ref(false);
    const fileUploadErrorMessage = ref('');

    const acceptedUploadFormats = ref(Object.values(fileFormats));
    const isMultipleFilesSelected = ref(false);

    // Methods
    async function uploadFile(e) {

        const formData = new FormData();

        let files;

        if (e.type == 'change') {

            if (e.target.files.length > 1) {
                isMultipleFilesSelected.value = true;
                hasFileUploaded.value = false;
                return;
            }

            files = e.target.files;
        }

        if (e.type == 'drop') {
            files = e.dataTransfer.files;
        }

        for (let file of files) {

            if (acceptedUploadFormats.value.indexOf(file.type) == -1) {
                isInvalidFileType.value = true;
                hasFileUploaded.value = false;
                return;

            } else {
                hasFileUploaded.value = false;
                isInvalidFileType.value = false;
                fileName.value = file.name;
                formData.append('file', file);
            }
        }

        active.value = false;

        const response = await store.addressList.uploadAddressList(formData);
        if (response.succeeded) {
            hasFileUploaded.value = true;
            fileUploadErrorMessage.value = '';
            emits('uploaded', true);
            emits('uploadedAddressList', response.uploadedListSummary);
            let firstAddressItem = response.uploadedListSummary.uploadedListItems[0].address;
            await store.shippingMethodHelper.updatesShippingMethods(firstAddressItem.addressLine1, firstAddressItem.countryCode);
        } else {
            hasFileUploaded.value = false;
            const message = (response.messages && response.messages[0]?.userMessage)
                || "Something went wrong, please try again later";
            fileUploadErrorMessage.value = message;
            emits('uploaded', false);
        }

    }

    const toggleActive = () => {
        active.value = !active.value;
    }

    // Emits ----
    const emits = defineEmits(['uploadedAddressList', 'uploaded']);

</script>

<template>

    <div class="file-drop-zone">
        <div class="row mb-2 invalid-file" v-if="isInvalidFileType">
            <div class="invalid-file-message p-2">
                <span><strong>File format not accepted</strong> </span>
                <span>Accepted file format is .xlsx and .csv  </span>
            </div>
        </div>

        <div class="row mb-2 invalid-file" v-if="isMultipleFilesSelected">
            <div class="invalid-file-message p-2">
                <span><strong>Selected multiple files</strong> </span>
                <span>Please select single file</span>
            </div>
        </div>

        <div class="row mb-2 invalid-file" v-if="fileUploadErrorMessage">
            <div class="invalid-file-message p-2">
                <span><strong>File upload failed</strong> </span>
                <span>{{ fileUploadErrorMessage }}</span>
            </div>
        </div>


        <div class="row mb-2 invalid-file" v-if="hasFileUploaded && fileName">
            <div class="valid-file-message p-2">
                <span><strong>File upload success</strong> </span>
                <span> {{fileName}} Has been loaded.</span>
            </div>
        </div>


        <div class="row upload-container">
            <div class="row mb-2 mt-2">
                <div class="file-upload mb-2">
                    <input class="dropzone"
                           @dragenter.prevent="toggleActive"
                           @dragleave.prevent="toggleActive"
                           @dragover.prevent
                           @drop.prevent="uploadFile($event)"
                           :class="{ 'active-dropzone' : active }" placeholder="Drop file here" />
                    <label class="btn btn-primary" for="formFile">Browse</label>
                    <input @change="uploadFile($event)" type="file" id="formFile" :accept="acceptedUploadFormats">
                </div>
                <div class="mb-2">
                    <label for="dropfile"> (Accepted file format is .xlsx and .csv)</label>
                </div>
                <div class="mb-2">
                    <a class="text-decoration-none" href="/downloads/Upload-List-Template.xlsx">Download Template File | </a>  <label class="fw-bold">Need Help? </label><a class="text-decoration-none" href="/downloads/Upload-List-Template-Sample.xlsx"> Download Sample File</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .file-drop-zone {

        .show-center {
            padding-left: 12px;
            padding-right: 12px;
        }

        .dropzone {
            border: 1px solid $color-1;
            display: inline-flex;
            padding: 6px 12px;
        }

        #formFile {
            display: none;
        }

        .active-dropzone {
            background: $color-1;
        }

        .invalid-file {
            padding: 6px 12px;
        }

        .invalid-file-message {
            display: inline-grid;
            color: red;
            border: 1px solid red;
        }

        .valid-file-message {
            display: inline-grid;
            color: green;
            border: 1px solid green
        }

        .upload-container {
            background: #f2fcfc;
            padding: 1rem;
        }
    }

    .accepted-file {
        color: #997870;
    }

    .file-upload {
        display: inline-flex;
    }
</style>